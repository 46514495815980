import React from 'react';

// Components
import { TextInputSalto } from '../../atoms';

// Assets
import { ReactComponent as LogoAsegurame } from '../../../assets/images/Logos/AsegurameLogoMovil.svg';

const Proximamente = () => {
  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen bg-proximamente bg-primary">
      <LogoAsegurame className="w-80 max-sm:w-56 h-auto mb-16 max-md:mb-10" />
      <div className="grid gap-4 max-sm:gap-2 text-center justify-items-center">
        <TextInputSalto
          texto={`Pronto, \n una experiencia única en marcha`}
          className="fontPSemiBold text-[40px] max-sm:hidden text-contrast-content"
          classDiv="text-center"
        />
        <TextInputSalto
          texto={`Pronto, \n una experiencia única \n en marcha`}
          className="fontPSemiBold text-[28px] leading-9 hidden max-sm:block text-contrast-content"
          classDiv="text-center"
        />
        <TextInputSalto
          texto={`Estamos trabajando en algo extraordinario: \n una forma completamente nueva de adquirir tu seguro.`}
          className="text-contrast-content text-lg max-sm:hidden text-center fontPregular"
          classDiv="text-center"
        />
        <TextInputSalto
          texto={`Estamos trabajando en algo \n extraordinario: una forma completamente \n nueva de adquirir tu seguro.`}
          className="text-contrast-content text-lg hidden max-sm:block text-center fontPregular"
          classDiv="text-center"
        />
        <img
          src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/Loading.webp"
          alt="icon"
          className="mt-10 max-sm:w-72"
        />
      </div>
    </div>
  );
};

export default Proximamente;
