import { useState } from 'react';

// Components
import { ButtonCustom, CheckBoxCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';
/* import { RenderBoldText } from '../../../common/constants/componenteTextoModales'; */
const ModalEnfermedades = ({
  open = false,
  setOpen = () => null,
  aceptacionModal = () => null,
}) => {
  const [acept, setAcept] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleBtn = () => {

    setBtnDisabled(acept);
    setAcept(!acept);
  };



  
  return (
    <DialogCustom
      open={open}
      setOpen={setOpen}
      title={'Declaración de Padecimientos'}
      disabledDismiss
    >
      <DialogContent className="pt-0 px-0 md:px-6 fontPRegular">
        <div className="relative my-5 w-auto  max-h-[338px] max-w-[800px] text-xs lg:text-sm">
          <TextCustom
            text="Declaro que tanto mi persona como el asegurado dependiente, no padecemos o hemos padecido y no estamos siendo estudiados, ni esperando resultados por alguna de las siguientes enfermedades:"
            className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base"
          />
          <div className="px-5 py-7">
            <ol className="custom  fontPRegular leading-5 sm:text-sm xsm:text-[15px] md:text-base">
              <li>Hipertensión Arterial</li>
              <li>Infarto de Miocardio</li>
              <li>Derrame Cerebral</li>
              <li>Diabetes Mellitus</li>
              <li>Tumores malignos o cáncer</li>
              <li>SIDA</li>
              <li>Infecciones por el virus VIH (SIDA)</li>
              <li>Bronquitis crónica o enfisema</li>
              <li>Insuficiencia Renal</li>
              <li>Cirrosis Hepática</li>
            </ol>
          </div>
          <div className="grid justify-center w-full items-center">

            {/* <RenderBoldText /> */}
            <div className='flex  justify-center w-full items-center' >
            <CheckBoxCustom value={acept} setValue={handleBtn} />
            <TextCustom
              text="Confirmo que he leído y no padezco ninguna de las enfermadades mencionadas"
              className="sm:text-sm xsm:text-[15px] md:text-base"
            />
            </div>
        
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full pb-6">
          <ButtonCustom
            text="Contactame"
            typeColor="danger"
            onClick={() => {
              aceptacionModal(false);
            }}
            width={'150px'}
            height={'45px'}
            className="mr-5 text-xs sm:text-sm xsm:text-[15px] md:text-base"
          />
          <ButtonCustom
            text="Aceptar"
            typeColor="primary"
            onClick={() => aceptacionModal(true)}
            width={'150px'}
            height={'45px'}
            disabled={btnDisabled}
            className="text-xs sm:text-sm xsm:text-[15px] md:text-base"
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalEnfermedades;
