import React from 'react';

// Componets
import { TextCustom } from '../../atoms';

// Core
import { colors } from '../../styles/theme';

// Hooks
import { useCotizador } from '../../../hooks/others';

const { primary, texto } = colors;

const PasosItem = ({
  isActual = false,
  name = '',
  enabled = false,
  isChecked = false,
  onClick = () => null,
  paso = '',
  pasoActual = '',
}) => {
  const { pasos } = useCotizador();

  const handleClick = () => {
    if (pasos[pasoActual - 1].nombre !== 'confirmacion')
      enabled && onClick(paso);
  };
  return (
    <div
      onClick={handleClick}
      className="flex justify-center items-center gap-2 items-pasos"
      style={{ cursor: enabled ? 'pointer' : 'context-menu' }}
    >
      <div
        className="fontPBold flex justify-center items-center text-xs contenedor-tab-item"
        style={{
          borderRadius: '50%',
          width: '22px',
          height: '22px',
          backgroundColor: isActual || isChecked ? primary : 'var(--color-secondary-100)',
          color: isActual || isChecked ? 'white' : primary,
        }}
      >
        {paso}
      </div>
      <div className="titulo-web">
        <div className="flex justify-center items-center">
          <TextCustom
            text={name}
            className={`fontPSemiBold capitalize text-sm ${
              isActual || isChecked
                ? 'color-secondary'
                : pasoActual === 1
                ? 'text-primary-content'
                : texto
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default PasosItem;
