import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Funciones
import { textBlog } from '../../../core/utils';

const EntradasBlog = ({ entrada, index, estaMostrando, notas }) => {
  const navigate = useNavigate();
  const subtituloRef = useRef(null);

  const { titulo, fecha, subtitulo, autor, urlSlug, imagen, fotografia } =
    entrada;

  useEffect(() => {
    subtituloRef.current.innerHTML = textBlog(subtitulo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    navigate(`/blog-detalle?${urlSlug}`, { state: { entrada, notas } });
  };
  return (
    <div onClick={handleClick} className="cursor-pointer">
      <div
        className={`bg-card hover:scale-105 transition-all duration-300 ${
          index > 6 && !estaMostrando ? 'ocultar' : 'block'
        } `}
      >
        <div className="img-card xxs:h-auto xxs:mx-auto">
          <img
            src={imagen}
            alt="Imagen card"
            className="w-full h-full xxs:h-auto xxs:mx-auto"
          />
        </div>

        <div className="contenido-card text-left">
          <h3 className="color-primary fontPSemiBold h-28 ">{titulo}</h3>
          <h6 className="fontPRegular text-xs mt-10 color-primary">{fecha}</h6>
          <p
            className="subtitulo-card fontPRegular mt-5 color-tertiary text-sm leading-5"
            ref={subtituloRef}
          ></p>

          <div className="footer-card mt-10 flex justify-between items-center">
            <p className="fontPRegular color-tertiary text-xs">
              Por: {autor}
            </p>
            <div className="w-9 h-9 ">
              <img
                src={fotografia}
                alt="Foto publicador"
                className="w-full rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntradasBlog;
