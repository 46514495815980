import { memo } from 'react';

// Components
import { InputLabel, Select } from '@mui/material';
import { FormControlCustom, MenuItemCustom, TextCustom } from '../';

// Assets
import { ReactComponent as SelectIcon } from '../../../assets/icons/SelectIcon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/tablas/Icono-Filtro.svg';

// Styles
import './style.css';

// Core
import { colors } from '../../styles/theme';

const { primary, black, white, ligthGray, error, bordes, general } = colors;

const SelectCustom = ({
  name = null,
  options = [],
  value = '',
  setValue = () => null,
  msgError = '',
  success = false,
  disabled = false,
  required = false,
  fontSize = 18,
  className = '',
  defaultValue = '',
  borderStyle = false,
  placeholder = '',
  errorAlert = false,
  filtro = false,
  isTypeButton = false,
  alinear = '',
  onlyIconOrLabel = 'label',
  radiusBorder = 5,
  altura = '',
  bgTransparente = false,
  overflow = '',
  index = '',
  nameValue = '',
  changeValid = () => null,
}) => {
  const handleChange = event => {
    const inputValue = event.target.value;

    if (nameValue) setValue(inputValue, index, nameValue);
    else setValue(inputValue);
    changeValid(nameValue, inputValue);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <FormControlCustom required={required}>
        <InputLabel id="demo-simple-select-label" style={{ fontSize }}>
          {name}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label={name}
          value={value ? value : ''}
          defaultValue={defaultValue}
          onChange={handleChange}
          className={`w-full bg-negro text-base ${altura ? altura : 'h-auto'} `}
          size="large"
          disabled={disabled}
          inputProps={{ style: { padding: '13.5px 14px' } }}
          IconComponent={isTypeButton ? FilterIcon : SelectIcon}
          placeholder={placeholder}
          displayEmpty
          startAdornment={filtro && <FilterIcon className="w-10 h-4" />}
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: overflow ? overflow : 'scroll',
                scrollbarWidth: 'thin',
                borderRadius: 10,
              },
              className: "text-black",
            },
            className: 'z-[2100] mui-md:z-[1300] text-black',
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: bgTransparente ? white : general,
              fontFamily: 'Poppins-SemiBold',
              // paddingLeft: '10px',
            },

            //cuando hace foco
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: bgTransparente ? '#var(--color-contrast-content)' : 'var(--color-primary)',
              color: 'var(--color-primary)',
            },

            //cuando hace hover
            '&.MuiOutlinedInput-root': {
              '&:hover > fieldset': {
                border:
                  msgError.length > 0 || success || errorAlert
                    ? 2
                    : borderStyle
                      ? 1
                      : 0,
                borderColor:
                  msgError.length > 0 || errorAlert
                    ? error
                    : success
                      ? primary
                      : borderStyle
                        ? bordes
                        : 'transparent',
              },
            },

            '$.MuiSelect-select': {
              padding: '10px 14px',
            },
            '& MuiPaper-root': {
              marginTop: 1,
              maxHeight: '300px',
            },
            '& MuiMenu-paper': {
              background: error,
            },
            '& legend': {
              marginLeft: 2,
              fontSize: fontSize * 0.82,
            },
            '& fieldset': {
              borderRadius: filtro ? '10px' : radiusBorder,
              border:
                msgError.length > 0 || success || errorAlert
                  ? 2
                  : borderStyle
                    ? 1
                    : 0,
              borderColor:
                msgError.length > 0 || errorAlert
                  ? error
                  : success
                    ? primary
                    : borderStyle
                      ? bordes
                      : 'transparent',
              color: black,
              boxShadow: borderStyle && 'var(--sombra-secondary)' ,
            },

            backgroundColor: disabled
              ? ligthGray
              : bgTransparente
                ? 'transparent'
                : white,
            borderRadius: 2,
          }}
          >
          <MenuItemCustom
            className={`${!placeholder && 'hidden'} text-base`}
            disabled
            value=""
            alinear={alinear}
          >
            <span
              className={`fontPSemiBold text-sm md:text-base ${
                isTypeButton ? 'hidden' : 'block'
              }`}
              style={{
                color: filtro ? 'var(--color-primary)' : 'rgba(0,55,99,0.4)',
                fontWeight: filtro ? 'bold' : 'normal',
                marginLeft: filtro ? '10px' : '0',
                letterSpacing: '0',
                textAlign: filtro ? 'start' : '',
              }}
            >
              {placeholder}
            </span>
          </MenuItemCustom>
          {options.map((option, index) => (
            <MenuItemCustom key={index} value={option?.id} alinear={alinear}>
              {onlyIconOrLabel === 'icon' ? (
                option?.icon
              ) : onlyIconOrLabel === 'label' ? (
                option?.label
              ) : (
                <div className="flex justify-center items-center gap-5 mr-8">
                  {option?.icon}
                  {option?.label}
                </div>
              )}
            </MenuItemCustom>
          ))}
        </Select>
      </FormControlCustom>

      {msgError && (
        <div className="flex gap-2 items-center mt-2">
          <TextCustom
            text={msgError}
            className="text-xs fontPRegular color-danger"
          />
        </div>
      )}
    </div>
  );
};

export default memo(SelectCustom);
