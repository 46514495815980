//componentes
import { TextCustom } from '../../atoms';
import { CardsAhorros } from '../../molecules';

//assets
import Forma3 from '../../../assets/images/home/Forma-Asegurame-3.svg';

//style
import './style.css';

const SeccionEnfoque = () => {
  //assets
  const year27 =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/27years.svg';
  const Ventajas =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/Asegurame-QueEs.webp';

  return (
    <>
      <section
        className="md:pt-0 lg:pt-0 grid-seccion-enfoque relative bg-primary"
      >
        <img
          src={Forma3}
          alt="forma 3"
          className="hidden img-form3 absolute top-1/3 right-0 lg:block"
        />
        <div className="lg:container mx-auto px-8 md:px-14 2xl:px-40 lg:px-12 contenedor-enfoque py-20">
          <div className="contenido-ahorro">
            <div className="relative">
              <img src={year27} alt="15 años" className="year-15" />
              <div className="mb-10 texto">
                <h2 className="text-contrast-content fontPSemiBold text-[22px] lg:leading-[45px]  md:text-[35px]">
                  En asegurame <br />
                  nos enfocamos en ti
                </h2>
                <TextCustom
                  text="Somos una plataforma y corredor digital que te permite manejar tus seguros de forma sencilla, desde tu computadora o celular."
                  className="fontPRegular text-contrast-content text-[13px] md:text-[16px]"
                />
              </div>
              <div className="grid-card-ahorros">
                <CardsAhorros />
              </div>
            </div>
          </div>
          <div className="flex justify-center xl:justify-end mt-5">
            <img src={Ventajas} alt="Ventajas" className="img-enfoque" />
          </div>
        </div>
      </section>
    </>
  );
};

export default SeccionEnfoque;
