import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextInputCustom from '../../atoms/TextInputCustom';
import SelectCustom from '../../atoms/SelectCustom';
import {
  marcaVehiculosSelect,
  yearCarrosArray,
} from '../../../common/constants';
import { SelectMultiCustom, TextCustom } from '../../atoms';
import { capitalizarPalabra } from '../../../core/utils';
import { capitalizarMultiplesPalabras } from '../../../core/utils/functions';

const CollapseFormVehiculo = ({
  preguntas = [],
  formData = { Marca: [], Año: [], modeloVehiculo: '', Propietario: '' },
  setFormData,
  setDetalle,
  detalleMsgErrors,
  setDetalleMsgErrors,
  disabled,
}) => {
  // Manejar cambios en los selects
  const handleOnChange = (name, value) => {
    const formattedValue = Array.isArray(value)
      ? value.map(v => v.value)
      : capitalizarMultiplesPalabras(String(value.value));

    setFormData(prevData => ({
      ...prevData,
      [name]: formattedValue,
    }));
    setDetalle(prevData => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };
  // Manejar cambios en TextInputCustom
  const handleSetForm = (name, value) => {
    let formatValue = value;
    setFormData(prevData => ({
      ...prevData,
      [name]: formatValue,
    }));

    setDetalle(prevData => ({
      ...prevData,
      [name]: formatValue,
    }));

  };

  const filteredYearOptions = yearCarrosArray.filter(option => {
    return String(formData.Año) === String(option.value);
  });

  return (
    <div className="block">
      <div className="flex flex-col justify-center items-center text-center">
        <Accordion
          className="mb-5 md:w-4/5 lg:w-3/5 xl:w-2/5"
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            '&:before': {
              display: 'none',
            },
          }}
          expanded={true}
        >
          <AccordionDetails className="p-6 flex flex-col mb-0 items-center w-full gap-10">
            <div className="flex flex-col min-w-form gap-5">
              <div className="mt-5">
                <TextCustom
                  text="Propietario"
                  component="p"
                  variant="p"
                  className="color-primary fontPSemiBold text-base "
                />
              </div>
                <TextInputCustom
                  className="text-base mb-3 "
                  onChange={value => handleSetForm('Propietario', value)}
                  text="propietario"
                  textAlign="center"
                  value={formData.Propietario}
                  borderStyle={true}
                  nameValue={'propietario'}
                  placeholder="Ingrese el propietario"
                  type="text"
                  msgError={
                    detalleMsgErrors['propietarioError']
                      ? 'Ingresa el nombre del propietario'
                      : ''
                  }
                  disabled={disabled}
                />

            </div>

            <div className="flex flex-col min-w-form gap-5">

                <TextCustom
                  text="Marca de tu vehículo"
                  component="p"
                  variant="p"
                  className="color-primary fontPSemiBold text-base"
                />


                <SelectMultiCustom
                  options={marcaVehiculosSelect}
                  value={(marcaVehiculosSelect || []).filter(option =>
                    (formData.Marca.toLowerCase() || []).includes(option.value),
                  )}
                  setValue={value => handleOnChange('Marca', value)}
                  className=""
                  borderStyle={true}
                  placeholder="Selecciona una opción"
                  nameValue={'marcaVehiculo'}
                  msgError={
                    detalleMsgErrors['MarcaError']
                      ? 'Ingresa la marca del vehículo'
                      : ''
                  }
                  isMulti={false}
                  isDisabled={disabled}
                />
          
            </div>

            <div className="flex flex-col min-w-form gap-5">
              <div className="mt-5">
                <TextCustom
                  text="Modelo/Estilo de tu vehículo"
                  component="p"
                  variant="p"
                  className="color-primary fontPSemiBold text-base "
                />
              </div>
              <div className="flex flex-col min-w-form">
                <TextInputCustom
                  className="text-base mb-3 "
                  onChange={value => handleSetForm('modeloVehiculo', value)}
                  text="modelo"
                  textAlign="center"
                  borderStyle={true}
                  value={formData.modeloVehiculo}
                  nameValue={'modeloVehiculo'}
                  placeholder="Ingrese el modelo"
                  type="text"
                  msgError={
                    detalleMsgErrors['modeloVehiculoError']
                      ? 'Ingresa el modelo del vehículo'
                      : ''
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="flex flex-col min-w-form gap-5">
              <TextCustom
                text="¿Qué año es tu vehículo?"
                component="p"
                variant="p"
                className="color-primary fontPSemiBold text-base "
              />
    
      
              <div className="my-4" style={{ maxWidth: 350, width: '100%' }}>
                <SelectMultiCustom
                  options={yearCarrosArray}
                  value={filteredYearOptions}
                  setValue={value => handleOnChange('Año', value)}
                  placeholder="Selecciona una opción"
                  alinear="izquierda"
                  borderStyle={true}
                  nameValue={'Año'}
                  msgError={
                    detalleMsgErrors['AñoError']
                      ? 'Ingresa el año del vehículo'
                      : ''
                  }
                  isMulti={false}
                  isDisabled={disabled}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default CollapseFormVehiculo;
