// Components
import { Link } from 'react-router-dom';
import { ButtonCustom } from '../../atoms';
import { TitulosInformativa } from '../../molecules';
import { SeccionPersonal } from '../../organisms';
import CardNosotros from './CardNosotros';

// Assets
import LogoUniserse from '../../../assets/images/Logos/UNISERSELogo.svg';

//styles
import './style.css';

const Conocenos = () => {
  // Assets
  const SimplificarSeguro =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Conocenos/Asegurame-Simplificar.webp';
  const AsegurameEquipo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Conocenos/Asegurame-Nosotros.webp';

  return (
    <main>
      <TitulosInformativa
        titulo={`Reconstruimos \\n la experiencia de seguros`}
        descripcion={`En UNISERSE, fieles a nuestro ADN nos mantenemos innovando \\n en una nueva experiencia al comparar, cotizar y adquirir sus seguros, \\n con el servicio post-venta que siempre nos ha caracterizado.`}
        imagen={AsegurameEquipo}
        className="conocenos-experiencia"
        classNameText="contenido-experiencia"
        action={
          <ButtonCustom
            text="Escríbenos"
            typeColor="secondary"
            width="200px"
            className="fontPSemiBold lg:text-xl"
          />
        }
      />
      <section className="px-4 conocenos-quienes-somos">
        <div className="contenido-existencia">
          <h2 className="fontPBold color-accent">¿Por qué existimos?</h2>
          <p className="fontPSemiBold  color-primary">
            Asegurame tiene como propósito hacer los seguros
            <br className="salto-linea" /> más accesibles a través de una
            experiencia digital,
            <br className="salto-linea" />
            de la mano del servicio, experiencia y trayectoria de{' '}
            <Link
              to="https://linktr.ee/uniserse"
              className="color-secondary underline"
              target="_blank"
            >
              Uniserse.
            </Link>
          </p>
        </div>
      </section>
      <CardNosotros />
      <section className="px-4 conocenos-historia">
        <div className="container mx-auto">
          <div className="pt-16 pb-20 md:px-14 lg:px-0 contenido">
            <div className="grid-conocenos ">
              <div className="px-4 flex justify-center">
                <img src={SimplificarSeguro} alt="SimplificarSeguro" />
              </div>
              <div className="flex-contenido">
                <h2 className="fontPSemiBold color-primary text-center ml-4">
                  <p>
                    Desde el 1999, fundamos <br className="salto-linea" />{' '}
                    <Link
                      className="color-secondary underline"
                      to={'https://linktr.ee/uniserse'}
                      target="_blank"
                    >
                      UNISERSE
                    </Link>{' '}
                    con el propósito de llevar <br className="salto-linea" />{' '}
                    tranquilidad y generar relaciones{' '}
                    <br className="salto-linea" /> de largo plazo con nuestros
                    clientes <br className="salto-linea" />{' '}
                  </p>
                  <p className="mt-5 text-center">
                    Con{' '}
                    <span className="color-accent fontPSemiBold">
                      Asegurame{' '}
                    </span>
                    evolucionamos e <br className="salto-linea" /> incursionamos
                    en el diseño y asesoría <br className="salto-linea" />
                    digital de seguros, sin perder de vista{' '}
                    <br className="salto-linea" /> nuestro propósito.
                  </p>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cont-correduria">
        <div className="flex flex-col items-center mt-8 logo-texto">
          <img src={LogoUniserse} alt="LogoUniserse" className="logo-andina" />
          <p className="texto  fontPRegular color-primary mt-5">
            La página{' '}
            <span className="color-secondary font-bold">asegurame.cr </span>
            está gestionada por{' '}
            <Link
              to={'https://linktr.ee/uniserse'}
              target="_blank"
              className="texto underline fontPSemiBold"
            >
              UNISERSE
            </Link>
          </p>

          <p></p>
        </div>
      </section>
      <SeccionPersonal />
    </main>
  );
};

export default Conocenos;
