//components
import HelpIcon from '@mui/icons-material/Help';

//styles
import './style.css';

const Preguntita = ({ titulo = '' }) => {
  return (
    <div className="custom-tooltip hover:cursor-pointer flex justify-center items-center">
      <HelpIcon
        fontSize="small"
        sx={{
          fill: 'var(--color-secondary)',
        }}
      />
      <span className="custom-tooltiptext text-white">{titulo}</span>
    </div>
  );
};

export default Preguntita;
