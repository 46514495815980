import React, { useEffect, useState } from 'react';

// Components
import IconButtonCustom from '../IconButtonCustom';

// Assets
import { ReactComponent as AdvertenciaIcon } from '../../../assets/icons/Alerts/AdvertenciaIconAlert..svg';
import { ReactComponent as ListoIcon } from '../../../assets/icons/Alerts/ListoIconAlert.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/Alerts/ErrorIconAlert.svg';
import { ReactComponent as AlertCloseIcon } from '../../../assets/icons/Alerts/AlertCrossIcon.svg';

// Styles
import './style.css';
import { colors } from '../../styles/theme';

const { primary, warning, white, error, green } = colors;

const AlertCustom = ({
  description = '',
  open = true,
  heightClass = "h-[5rem]",
  resetValues = () => null,
  severity = 'info',
  size = '380px',
  title = '',
  setOpen = () => null,
}) => {
  const [showMore, setShowMore] = useState(true);
  const [pause, setPause] = useState(false);
  const [sizeAlerta, setSizeAlerta] = useState(295);

  useEffect(() => {
    if (open) {
      let tamAlert = 295;

      if (description.length > 100) {
        tamAlert = 325;
      }
      setSizeAlerta(tamAlert);
      let timeout = 0;
      switch (severity) {
        case 'danger':
          timeout = 4000;
          break;
        case 'warning':
          timeout = 3000;
          break;
        case 'success':
          timeout = 2000;
          break;
        case 'info':
          timeout = 3000;
          break;
        default:
          timeout = 2000;
          break;
      }
      if (!pause) {
        let id = setInterval(() => {
          resetValues();
          setShowMore(true);
        }, timeout);
        return () => clearInterval(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, pause]);

  const renderTitle = () => {
    let text = '';
    if (title) {
      text = title;
    } else {
      switch (severity) {
        case 'danger':
          text = 'Error';
          break;
        case 'warning':
          text = 'Advertencia';
          break;
        case 'success':
          text = 'Listo';
          break;
        case 'error':
          text = 'Error';
          break;
        default:
          text = 'Información';
          break;
      }
    }
    return text;
  };

  const renderIcon = () => {
    let icon = null;
    switch (severity) {
      case 'info':
        icon = <AdvertenciaIcon />;
        break;
      case 'error':
        icon = <ErrorIcon />;
        break;
      case 'warning':
        icon = <AdvertenciaIcon />;
        break;
      case 'success':
        icon = <ListoIcon />;
        break;
      default:
        icon = <AdvertenciaIcon />;
        break;
    }
    return icon;
  };

  const renderColor = () => {
    let color = '';
    switch (severity) {
      case 'error':
        color = error;
        break;
      case 'warning':
        color = warning;
        break;
      case 'success':
        color = green;
        break;
      case 'info':
        color = primary;
        break;
      default:
        color = primary;
        break;
    }
    return color;
  };

  const renderTypeColor = () => {
    let type = '';
    switch (severity) {
      case 'error':
        type = 'danger';
        break;
      case 'warning':
        type = 'warning';
        break;
      case 'success':
        type = 'success';
        break;
      case 'info':
        type = 'primary';
        break;
      default:
        type = 'primary';
        break;
    }
    return type;
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  return (
    <div className={`popup ${open ? 'popup-open' : 'popup-close'}`}>
      <div className="flex  justify-center sm:justify-end">
        <div className="flex w-[95%] justify-end  md:w-full ">
          <div
            className="popup-alert xs:min-w-[420px] bg-contrast-content rounded-[20px]"
            onMouseEnter={() => setPause(true)}
            onMouseLeave={() => setPause(false)}
          >
            <div
              className={`flex relative justify-between ${heightClass} rounded-[20px] shadow-custom`}
            >
              <div className="flex gap-3" style={{ cursor: 'pointer' }}>
                <div
                  className="alert-icon justify-center min-w-[5rem] "
                  style={{
                    background: renderColor(),
                    borderRadius: '20px 0 0 20px',
                  }}
                  id={severity}
                >
                  {renderIcon()}
                </div>
                <div
                  className="alert-line fontLBold text-base flex flex-col justify-center"
                  style={{
                    width: sizeAlerta + 'px',
                  }}
                >
                  <div
                    className="fontPSemiBold"
                    style={{ color: renderColor() }}
                  >
                    {renderTitle()}
                  </div>
                  <div className="fontPRegular text-primary-content text-sm ">
                    {description}
                  </div>
                </div>
              </div>
              <div className="mr-4 flex justify-center items-center">
                <IconButtonCustom
                  onClick={handleClose}
                  size={14}
                  typeColorHover={'var(--color-neutral)'}
                  icon={<AlertCloseIcon fill={'var(--color-contrast-content)'} />}
                  padding="6px"
                  typeColor={renderTypeColor()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertCustom;
