import React from 'react';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

import { colors } from '../../styles/theme';

const { primary, error, green, warning } = colors;

const ModalAlertas = ({
  open = false,
  setOpen = () => null,
  contenido = '',
  aceptacionModal = () => null,
  severity = 'success',
  onClose = false,
  title = '',
}) => {
  // Assets
  const AdvertenciaIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/Alerts/AdvertenciaIcon.webp';
  const ListoIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/Alerts/ListoIcon.webp';
  const ErrorIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/Alerts/ErrorIcon.webp';
  const InformativoIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/Alerts/InformativoIcon.webp';

  const renderTitle = () => {
    let text = '';
    if (title) {
      text = title;
    } else {
      switch (severity) {
        case 'danger':
          text = 'Error';
          break;
        case 'warning':
          text = 'Advertencia';
          break;
        case 'success':
          text = '¡Listo!';
          break;
        case 'error':
          text = 'Error';
          break;
        default:
          text = 'Informativo';
          break;
      }
    }
    return text;
  };

  const renderIcon = () => {
    let icon = null;
    switch (severity) {
      case 'info':
        icon = (
          <img
            src={InformativoIcon}
            alt="InformativoIcon"
            className="h-auto w-[55%] lg:w-[45%]"
          />
        );
        break;
      case 'error':
        icon = (
          <img
            src={ErrorIcon}
            alt="ErrorIcon"
            className="h-auto w-[55%] lg:w-[45%]"
          />
        );
        break;
      case 'warning':
        icon = (
          <img
            src={AdvertenciaIcon}
            alt="AdvertenciaIcon"
            className="h-auto w-[55%] lg:w-[45%]"
          />
        );
        break;
      case 'success':
        icon = (
          <img
            src={ListoIcon}
            alt="ListoIcon"
            className="h-auto w-[55%] lg:w-[45%]"
          />
        );
        break;
      default:
        icon = (
          <img
            src={InformativoIcon}
            alt="InformativoIcon"
            className="h-auto w-[55%] lg:w-[45%]"
          />
        );
        break;
    }
    return icon;
  };

  const renderColor = () => {
    let color = '';
    switch (severity) {
      case 'error':
        color = error;
        break;
      case 'warning':
        color = warning;
        break;
      case 'success':
        color = green;
        break;
      case 'info':
        color = primary;
        break;
      default:
        color = primary;
        break;
    }
    return color;
  };

  return (
    <DialogCustom
      open={open}
      setOpen={setOpen}
      border={renderColor()}
      severity={severity}
    >
      <DialogContent className="pt-0 px-0 md:px-6 fontPRegular">
        <div className="relative my-5 mt-0 w-auto lg:w-[530px]">
          <div className="flex justify-center items-center lg:mt-12">
            {renderIcon()}
          </div>
          <div className="w-full text-center">
            <TextCustom
              text={renderTitle()}
              className="text-center color-primary fontPSemiBold text-xl md:text-4xl"
            />
          </div>
          <div className="text-center w-[80%] mx-auto mt-2">
            <TextCustom
              text={contenido}
              className="fontPRegular text-sm lg:text-base"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full gap-4 mb-2">
          {onClose && (
            <ButtonCustom
              text="Agregar"
              typeColor="general"
              onClick={() => setOpen(false)}
              width={'150px'}
              height={'45px'}
            />
          )}
          <ButtonCustom
            text={onClose ? 'Continuar' : 'Ok'}
            typeColor="general"
            onClick={() => {
              if (onClose) {
                aceptacionModal(true);
              } else {
                setOpen(false);
              }
            }}
            width={'150px'}
            height={'45px'}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalAlertas;
