import { lazy, Suspense } from 'react';

// Componetenes
import { TextDetallePago } from '../../components/molecules';

// Colores
import { colors } from '../../components/styles/theme';
const { general, primary, secondary, green, warning, gray, white, error } =
  colors;

// Formularios
const Seguro = lazy(() => import('../../components/organisms/PasoInicial'));
const Personal = lazy(() => import('../../components/organisms/PasoPersonal'));
const Resultado = lazy(
  () => import('../../components/organisms/PasoResultado'),
);
const Pago = lazy(() => import('../../components/organisms/PasoPago'));
const Emision = lazy(() => import('../../components/organisms/PasoEmision'));
const Confirmacion = lazy(
  () => import('../../components/organisms/PasoConfirmacion'),
);

export const renderColor = (typeColor = '') => {
  let customColor = undefined;
  switch (typeColor) {
    case 'general':
      customColor = general;
      break;
    case 'primary':
      customColor = primary;
      break;
    case 'secondary':
      customColor = secondary;
      break;
    case 'success':
      customColor = green;
      break;
    case 'danger':
      customColor = error;
      break;
    case 'warning':
      customColor = warning;
      break;
    case 'default':
      customColor = gray;
      break;
    case 'white':
      customColor = white;
      break;
    default:
      customColor = undefined;
      break;
  }
  return customColor;
};

export const renderForm = (paso = '') => {
  let customForm = undefined;

  switch (paso) {
    case 'seguro':
      customForm = (
        <Suspense>
          <Seguro />
        </Suspense>
      );
      break;
    case 'personal':
      customForm = (
        <Suspense>
          <Personal />
        </Suspense>
      );
      break;
    case 'resultado':
      customForm = (
        <Suspense>
          <Resultado />
        </Suspense>
      );
      break;

    case 'emision':
      customForm = (
        <Suspense>
          <Emision />
        </Suspense>
      );
      break;
    case 'pago':
      customForm = (
        <Suspense>
          <Pago />
        </Suspense>
      );
      break;
    case 'confirmacion':
      customForm = (
        <Suspense>
          <Confirmacion />
        </Suspense>
      );
      break;
    default:
      customForm = undefined;
      break;
  }
  return customForm;
};

export const renderDetalleSeguro = (
  seguro = '',
  dataCotizar = {},
  sumasAsegurada = '',
) => {
  switch (seguro) {
    case 'SEG-V':
      return (
        <>
          <TextDetallePago
            titulo="Nombre"
            detalle={
              dataCotizar?.datosPersonales?.primerNombre +
              ' ' +
              dataCotizar?.datosPersonales?.primerApellido
            }
          />
          <TextDetallePago
            titulo="N. De Identificación"
            detalle={dataCotizar?.datosPersonales?.numeroIdentificacion}
          />
          {/* <TextDetallePago
            titulo="Tipo"
            detalle={dataCotizar?.dataSeguro?.tipoPlan}
          /> */}
          <TextDetallePago
            titulo="Destinos"
            detalle={dataCotizar?.dataSeguro?.destinoViaje?.map(
              destino => destino.label + `\n`,
            )}
          />
          <TextDetallePago
            titulo="Fecha de Salida"
            detalle={dataCotizar?.dataSeguro?.fechaViaje?.split('-')[0]}
          />
          <TextDetallePago
            titulo="Fecha de regreso"
            detalle={dataCotizar?.dataSeguro?.fechaViaje?.split('-')[1]}
          />
        </>
      );
    case 'SEG-AEG':
      return (
        <>
          <TextDetallePago
            titulo="Nombre"
            detalle={
              dataCotizar.datosPersonales.primerNombre +
              ' ' +
              dataCotizar.datosPersonales.primerApellido
            }
          />
          <TextDetallePago
            titulo="N. De Identificación"
            detalle={dataCotizar.datosPersonales.numeroIdentificacion}
          />
          <TextDetallePago
            titulo={'Seguro de'}
            detalle={dataCotizar.seguro.nombre.split('Seguro de')[1]}
          />
          <TextDetallePago titulo="Suma Asegurada" detalle={sumasAsegurada} />
        </>
      );

    case 'SEG-ONC':
      return (
        <>
          <TextDetallePago
            titulo="Nombre"
            detalle={
              dataCotizar.datosPersonales.primerNombre +
              ' ' +
              dataCotizar.datosPersonales.primerApellido
            }
          />
          <TextDetallePago
            titulo="N. De Identificación"
            detalle={dataCotizar.datosPersonales.numeroIdentificacion}
          />
          <TextDetallePago
            titulo={'Seguro'}
            detalle={dataCotizar.seguro.nombre.split('Seguro')[1]}
          />
          <TextDetallePago titulo="Suma Asegurada" detalle={sumasAsegurada} />
        </>
      );

      case 'SEG-VID':
        return (
          <>
            <TextDetallePago
              titulo="Nombre"
              detalle={
                dataCotizar.datosPersonales.primerNombre +
                ' ' +
                dataCotizar.datosPersonales.primerApellido
              }
            />
            <TextDetallePago
              titulo="N. De Identificación"
              detalle={dataCotizar.datosPersonales.numeroIdentificacion}
            />
            <TextDetallePago
              titulo={'Seguro'}
              detalle={dataCotizar.seguro.nombre.split('Seguro')[1]}
            />
            <TextDetallePago titulo="Suma Asegurada" detalle={sumasAsegurada} />
          </>
        );

    case 'SEG-DA':
      return (
        <>
          <TextDetallePago
            titulo="Nombre"
            detalle={
              dataCotizar.datosPersonales.primerNombre +
              ' ' +
              dataCotizar.datosPersonales.primerApellido
            }
          />
          <TextDetallePago
            titulo={'Seguro de'}
            detalle={dataCotizar.seguro.nombre.split('Seguro de')[1]}
          />
          <TextDetallePago titulo="Suma Asegurada" detalle={sumasAsegurada} />
          <TextDetallePago
            titulo="Placa"
            detalle={dataCotizar.dataSeguro.placa.toUpperCase()}
          />
        </>
      );
    default:
      return null;
  }
};

// Render Imagenes de seguros
const IconoViaje =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Big.svg';
const IconoVehiculo =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Big.png';
const IconoEnfermedades =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Big.svg';
const IconoOncologico =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
const IconoVidaPlus =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Icono-Small.webp';

const seguroInfo = {
  'SEG-V': {
    icono: IconoViaje,
    fondo: 'bg-seguro-viaje',
  },
  'SEG-DA': {
    icono: IconoVehiculo,
    fondo: 'bg-seguro-vehiculo',
  },
  'SEG-AEG': {
    icono: IconoEnfermedades,
    fondo: 'bg-seguro-enfermedades',
  },
  'SEG-ONC': {
    icono: IconoOncologico,
    fondo: 'bg-seguro-oncologico',
  },
  'SEG-VID': {
    icono: IconoVidaPlus,
    fondo: 'bg-seguro-vidaplus',
  },
  default: {
    icono: IconoEnfermedades,
    fondo: 'bg-seguro-enfermedades',
  },
};

export const renderIconoSeguro = (seguro = '') => {
  const { icono } = seguroInfo[seguro] || seguroInfo.default;
  return icono;
};

export const renderFondoSeguro = (seguro = '') => {
  const { fondo } = seguroInfo[seguro] || seguroInfo.default;
  return fondo;
};

const asegurameViaje =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Small.webp';
const asegurameVehiculo =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Small.webp';
const asegurameMedico =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Small.webp';
const asegurameOncologico =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
const asegurameVidaPlus =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Icono-Small.webp';

export const renderImagen = {
  'SEG-DA': (
    <img
      id="vehiculo"
      src={asegurameVehiculo}
      alt="asegurameVehiculo"
      className="icono-cotizar lg:h-[3.5rem] lg:w-auto xxxs:h-[2rem] xxxs:w-auto"
    />
  ),
  'SEG-V': (
    <img
      id="viaje"
      src={asegurameViaje}
      alt="asegurameViaje"
      className="icono-cotizar xxxs:h-[3rem] xxxs:w-auto lg:size-auto"
    />
  ),
  'SEG-AEG': (
    <img
      id="medico"
      src={asegurameMedico}
      alt="asegurameMedico"
      className="icono-cotizar lg:h-[3.2rem] lg:w-auto xxxs:h-[2.3rem] xxxs:w-auto"
    />
  ),
  'SEG-ONC': (
    <img
      id="oncologico"
      src={asegurameOncologico}
      alt="asegurameMedico"
      className="icono-cotizar"
    />
  ),
  'SEG-VID': (
    <img
      id="vidaPlus"
      src={asegurameVidaPlus}
      alt="asegurameVidaPlus"
      className="icono-cotizar"
    />
  ),
};

export const renderNombre = nombre => {
  return nombre.split('Seguro de')[1] || nombre.split('Seguro')[1];
};
