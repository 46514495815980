import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';
import {
  CardAccordion,
  RenderCoberturas,
  TitulosInformativa,
} from '../../molecules';

// Const
import { informativaSeguro, rutasCotizar } from '../../../common/constants';

//styles para carousel en vista movil
import './style.css';

// Hooks
import { useApp } from '../../../hooks/others';

const InformativaSeguro = ({
  imagen,
  nombreSeguro = 'ejemplo',
  coberturas = [],
  codigoSeguro = '',
  preguntas = [],
  requisitos = {},
}) => {
  const navigate = useNavigate();
  const { seguros } = useApp();

  // Assets
  const Enfermedades =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Necesario-EnfermedadesGraves.webp';
  const Automovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Necesario-DeducibleAutomovil.webp';
  const Viajes =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Necesario-Viaje.webp';
  const PreguntasImg =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Redireccion-PreguntasFrecuentes.webp';
  const seguroOncologicoPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Oncologico-Necesario.svg';
  const seguroVidaPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Informativas/Necesario-VidaPlus.webp';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const renderSeguro = {
    'SEG-DA': {
      titulo: 'Deducible de Automóvil',
      renderImagen: Automovil,
    },
    'SEG-AEG': {
      titulo: 'Enfermedades Graves',
      renderImagen: Enfermedades,
    },
    'SEG-V': {
      titulo: 'Viaje',
      renderImagen: Viajes,
    },
    'SEG-ONC': {
      titulo: 'Seguro Oncológico Plus',
      renderImagen: seguroOncologicoPlus,
    },
    'SEG-VID': {
      titulo: 'Seguro Vida Plus',
      renderImagen: seguroVidaPlus,
    },
  };

  const handlePreguntas = () => {
    navigate(`/preguntas-frecuentes`, { state: codigoSeguro });
  };

  const handleClick = () => {
    const url = rutasCotizar.find(ruta => ruta.codigo === codigoSeguro)?.ruta;
    const data = seguros.find(seguro => seguro.codigo === codigoSeguro);
    navigate(url, { state: data });
  };

  return (
    <>
      <TitulosInformativa
        titulo={
          informativaSeguro.find(seguro => seguro.codigo === codigoSeguro)
            ?.titulo
        }
        descripcion={
          informativaSeguro.find(seguro => seguro.codigo === codigoSeguro)
            ?.descripcion
        }
        imagen={imagen}
        className="contacto-imagen-texto"
        classNameText="contenido-informativa"
        classNameImg={`imagen-informativa imagen-${codigoSeguro}`}
        activeButton
        urlBtn={rutasCotizar.find(ruta => ruta.codigo === codigoSeguro)?.ruta}
        tipo={seguros.find(seguro => seguro.codigo === codigoSeguro)}
      />

      <div className="pt-5 md:pt-0 px-4">
        <div className="lg:mb-24 md:px-10 lg:px-24 xl:px-0 2xl:px-36 container mx-auto">
          <TextCustom
            text="Coberturas"
            className="text-[22px] text-center color-primary fontPSemiBold lg:text-[35px] "
            component="h2"
            variant="h2"
          />
          <p className="mt-4 text-center text-primary-content fontPRegular text-sm mb-5 lg:text-base">
            Descubre los beneficios al contratar{' '}
            <br className="min-[428px]:hidden" />
            un {nombreSeguro.toLocaleLowerCase()}
          </p>

          <div
            className={`flex flex-wrap justify-center lg:items-center mt-8 mb-14 gap-1 md:my-14 md:gap-8`}
          >
            {coberturas.map(
              (cobertura, index) =>
                cobertura.codigo !== '996' && (
                  <RenderCoberturas cobertura={cobertura} key={index} />
                ),
            )}
          </div>
        </div>
        <div className="pt-10 px-5 lg:mb-24 lg:ml-auto lg:px-20 2xl:px-24 container mx-auto">
          <div className="bg-shadow rounded-[20px] text-center w-[calc(100%+2rem)] -ml-4 2xl:h-[350px]">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-10">
              <div
                className={` ${
                  ['SEG-AEG'].includes(codigoSeguro)
                    ? 'lg:w-[70%] lg:h-[40%] xl:max-w-md 2xl:max-w-sm'
                    : 'lg:w-[75%] xl:max-w-lg 2xl:max-w-md'
                } -translate-y-5 w-[80%] mx-auto sm:w-[55%] `}
              >
                <img
                  src={renderSeguro[codigoSeguro]?.renderImagen}
                  alt="porque es necesario img"
                  className="w-full h-full"
                />
              </div>

              <div
                className={`text-center px-5 md:px-[10%] lg:px-0 lg:text-left lg:text-[20px] lg:pt-10`}
              >
                {
                  informativaSeguro.find(
                    seguro => seguro.codigo === codigoSeguro,
                  )?.necesario
                }
                <TextCustom
                  className="mt-3 fontPRegular text-primary-content text-sm text-center lg:text-base lg:text-left"
                  text={
                    informativaSeguro.find(
                      seguro => seguro.codigo === codigoSeguro,
                    )?.texto
                  }
                  component="p"
                  variant="p"
                />
                <ButtonCustom
                  text="Comprar"
                  className="w-[150px] mb-5 lg:mb-14 lg:w-[160px] xl:mb-0 mt-7"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        {requisitos.length > 0 && (
          <div>
            <TextCustom
              text="Requisitos"
              className="mt-20 text-[22px] text-center color-primary fontPSemiBold lg:text-[35px] lg:mb-5"
              component="h2"
              variant="h2"
            />
            <div className="hidden lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:mb-24 md:px-10 lg:px-24 lg:ml-auto xl:px-0 2xl:px-24 container mx-auto">
              {requisitos.map((requisito, index) => (
                <div className="px-5" key={requisito.titulo}>
                  <div
                    key={requisito.titulo}
                    className="flex flex-col justify-center bg-card my-20 lg:my-5"
                  >
                    <div className="w-20 h-20 rounded-full bg-shadow flex items-center justify-center">
                      <p className="color-secondary fontPBold text-[30px]">
                        {index.toString().length === 1
                          ? '0' + (index + 1)
                          : index}
                      </p>
                    </div>

                    <div className="flex items-center justify-between">
                      <TextCustom
                        text={requisito.titulo}
                        className="color-primary font-bold fontPRegular mt-14"
                      />
                    </div>
                    <TextCustom
                      text={requisito.descripcion}
                      className="text-secondary-content text-carrousel text-sm fontPRegular mt-5 mb-10"
                    />
                  </div>
                </div>
              ))}
            </div>

            <Slider
              {...settings}
              className="w-[calc(100%+2rem)] -ml-4 overflow-hidden informativa-slick-dots lg:hidden "
            >
              {requisitos.map((requisito, index) => (
                <div className="px-5" key={requisito.titulo}>
                  <div className="flex flex-col justify-center bg-card my-20  ">
                    <div className="w-20 h-20 rounded-full bg-shadow flex items-center justify-center">
                      <p className="color-secondary fontPBold text-[30px]">
                        {index.toString().length === 1
                          ? '0' + (index + 1)
                          : index}
                      </p>
                    </div>

                    <div className="flex items-center justify-between">
                      <TextCustom
                        text={requisito.titulo}
                        className="color-primary font-bold fontPRegular mt-14"
                      />
                    </div>
                    <TextCustom
                      text={requisito.descripcion}
                      className="text-secondary-content text-carrousel text-sm fontPRegular mt-5 mb-10"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
        <div className="mt-20 mb-28 container mx-auto lg:px-[120px] xl:px-[300px] 2xl:px-[350px] ">
          <TextCustom
            text="Glosario"
            className=" mb-5 text-[22px] text-center color-primary fontPSemiBold lg:text-[35px] "
            component="h2"
            variant="h2"
          />
          {preguntas.length > 0 && (
            <CardAccordion options={preguntas} titleVisible={false} />
          )}
          <div className="mt-16 flex text-center gap-3 justify-center items-center py-5 flex-col-reverse bg-primary-degrade px-8 md:px-14 md:text-left md:block md:py-4 rounded-[20px] relative bg-primary">
            <p className="fontPRegular text-sm md:text-base mt-20 md:mt-0 text-contrast-content">
              Si tienes más dudas, del seguro de{' '}
              {renderSeguro[codigoSeguro]?.titulo}{' '}
              <br className="hidden md:block" />
              visita nuestra página de{' '}
              <span
                className="fontPSemiBold underline cursor-pointer"
                onClick={handlePreguntas}
              >
                Preguntas Frecuentes.
              </span>
            </p>
            <img
              src={PreguntasImg}
              alt="preguntas-FQA"
              className="absolute icon-preguntas"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InformativaSeguro;
