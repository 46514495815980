import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

import { TitulosTexto } from '../../molecules';
import { TextCustom, TextInputSalto } from '../../atoms';

// Assets
import { ReactComponent as Forma2 } from '../../../assets/images/home/Forma-Asegurame-2.svg';

//styles
import './style.css';

// Styles Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SeccionComoFunciona = () => {
  const [initialized, setInitialized] = useState(false);

  // Assets
  const Selecciona =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/ComoFunciona-Paso1.webp';
  const CotizaYCompara =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/ComoFunciona-Paso2.webp';
  const AsesorateYContrada =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/ComoFunciona-Paso3.webp';
  const SiempreContigo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/ComoFunciona-Paso4.webp';

  const cards = [
    {
      titulo: '1. Selecciona',
      descripcion: 'El seguro que buscas e \n ingresa tus datos',
      imagen: (
        <img
          src={Selecciona}
          alt="selecciona"
          className="my-5 md:my-0 w-[180px] md:w-[150px]"
        />
      ),
    },
    {
      titulo: '2. Cotiza y elige',
      descripcion: 'Cotizaciones en menos \n de  1 minuto',
      imagen: (
        <img
          src={CotizaYCompara}
          alt="CotizaYCompara"
          className="my-5 md:my-0 w-[187px] md:w-[150px]"
        />
      ),
    },
    {
      titulo: '3. Contrata tu seguro',
      descripcion: 'Contrata y paga \n tu seguro en línea',
      imagen: (
        <img
          src={AsesorateYContrada}
          alt="AsesorateYContrada"
          className="my-5 md:my-0 w-[225px] md:w-[180px]"
        />
      ),
    },
    {
      titulo: '4. Siempre contigo',
      descripcion:
        'Al activar tu seguro, nuestro \n equipo de asesores estará a tu lado',
      imagen: (
        <img
          src={SiempreContigo}
          alt="SiempreContigo"
          className="my-5 md:my-0 w-[250px] md:w-[210px]"
        />
      ),
    },
  ];

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
  };
  return (
    <section
      id="cont_Scroll"
      className="relative pb-20 pt-10 xl:pt-10 xl:pb-24"
    >
      <Forma2 className="hidden absolute top-1/3  lg:block" />
      <div className="px-7 mx-auto lg:container xl:px-24 2xl:px-40">
        <div className="grid-imagen-texto lg:mt-10">
          <div className="contenido text-center">
            <TitulosTexto
              className="text-4xl titulo-como-funciona"
              titulo={'¿Cómo funciona?'}
            />
          </div>
          <div className="contenedor-funcion-web gap-5 sm:gap-4 lg:grid-cols-4">
            {cards.map((item, index) => (
              <div
                className="bg-card hover:scale-105 transition-all duration-300"
                key={index}
              >
                <div className="flex items-center justify-center lg:h-[175px]">
                  {item.imagen}
                </div>
                <div className="text-center mx-auto mt-4">
                  <TextCustom
                    text={item.titulo}
                    component="p"
                    variant="p"
                    className="color-primary fontPSemiBold"
                  />
                  <TextInputSalto
                    className="text-sm fontPRegular text-overlay mt-3"
                    texto={item.descripcion}
                  />
                </div>
              </div>
            ))}
          </div>
          {initialized && (
            <OwlCarousel
              {...options}
              className="owl-theme mt-0 mb-5 w-[calc(100%+3.5rem)] -ml-7 relative como-funciona-carousel"
            >
              {cards.map((item, index) => (
                <div
                  className="m-1 mt-7 bg-card w-[19rem] h-[23rem] mx-auto"
                  key={index}
                >
                  <div className="flex justify-center">{item.imagen}</div>
                  <div className="text-center mx-auto mt-4">
                    <TextCustom
                      text={item.titulo}
                      component="p"
                      variant="p"
                      className="color-primary fontPSemiBold"
                    />
                    <TextInputSalto
                      className="text-sm fontPRegular text-overlay mt-3"
                      texto={item.descripcion}
                    />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default SeccionComoFunciona;
