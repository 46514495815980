import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//Components
import { EntradasBlog } from '../../molecules';

// Funciones
import { textBlog } from '../../../core/utils';

//style
import './style.css';

const BlogDetalle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detalleRef = useRef(null);
  const subtituloRef = useRef(null);

  const [entrada, setEntrada] = useState({});
  const [entradasBlog, setEntradasBlog] = useState([]);

  useEffect(() => {
    if (location.state) {
      const nota = location.state.entrada;
      const notas = location.state.notas;

      detalleRef.current.innerHTML = textBlog(nota.detalle);
      subtituloRef.current.innerHTML = textBlog(nota.subtitulo);

      setEntrada(nota);
      setEntradasBlog(notas);
    } else {
      navigate(`/page-error-404`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <article className="px-5 mt-10 mb-24 md:px-10 lg:px-12 xl:px-16 2xl:px-28 container mx-auto entrada">
      <div className="p-4 lg:p-8">
        <h1 className="titulo fontPSemiBold color-primary xl:mb-0">
          {entrada.titulo}
        </h1>
        <p
          className="subtitulo fontPRegular text-center xl:mt-5"
          ref={subtituloRef}
        ></p>
        <p className="fecha fontPRegular color-primary text-center xl:mt-5">
          {entrada.fecha}
        </p>

        <div className="img-blog mt-5  xl:flex xl:justify-center">
          <img src={entrada.imagen} alt={`Imagen del blog ${entrada.titulo}`} />
        </div>

        <p
          className="contenido text-left fontPRegular mt-5 md:mt-14 lg:px-16 xl:px-24"
          ref={detalleRef}
        ></p>

        <div className="mt-10 flex justify-around items-center md:justify-center md:gap-32 lg:gap-56">
          <p className="color-primary fontPRegular">
            Publicado por :{' '}
            <span className="color-primary fontPSemiBold block ">
              {entrada.autor}
            </span>
          </p>
          <div className="w-14 h-14">
            <img
              src={entrada.fotografia}
              alt={`Imagen del autor ${entrada.autor}`}
              className={'w-full rounded-full'}
            />
          </div>
        </div>
      </div>

      <h2 className="color-primary text-center fontPSemiBold text-base mt-10 md:text-2xl">
        También te puede interesar...
      </h2>
      <div className="grid-blog-cards mt-5 xl:px-14">
        {entradasBlog.map((entrada, index) =>
          index < 3 ? (
            <EntradasBlog
              entrada={entrada}
              index={index + 1}
              key={entrada.id}
              notas={entradasBlog}
            />
          ) : (
            []
          ),
        )}
      </div>
    </article>
  );
};

export default BlogDetalle;
