//style
import './style.css';
import { ButtonCustom, TextCustom } from '../../atoms';


const TerminosCondiciones = () => {

  const onclick = () => {
    var link = document.createElement("a");
    link.href = "https://aseguramecr.s3.us-east-2.amazonaws.com/Documentos/TerminosyCondiciones-Asegurame-CostaRica.pdf";
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return (
    <article className="px-5 mt-10 mb-24 md:px-10 lg:px-12 xl:px-16 2xl:px-28 container mx-auto entrada">
      <div className="p-4 lg:p-8">

        <div className='flex justify-between mb-6'>
          <h1 className="titulo fontPSemiBold color-primary xl:mb-0">
            Términos y condiciones
          </h1>
          <ButtonCustom
              text="Descargar PDF"
              className="fontPSemiBold mt-3 text-[10px] text-xs md:-translate-x-7 w-fit"
              typeColor='general'
              onClick={onclick}
            />
        </div>

        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Información revelante
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          La cotización de los servicios que se ofrecen en este sitio requiere que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El uso de nuestros servicios implica que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El servicio de cotización de pólizas de seguros que son ofrecidas por nuestro sitio web, son propuestas de pólizas de seguros que están debidamente autorizadas por las diferentes compañías de seguros acreditadas y registradas ante la Superintendencia General de Seguros de Costa Rica. En algunos casos, para cotizar un seguro, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la cotización de alguno de nuestros productos. <strong><strong>www.asegurame.cr</strong></strong> no asume la responsabilidad en caso de que entregue dicha clave a terceros.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Todas las cotizaciones en línea se ofrecen como valores estimados y están sujetas a cambios en cualquier momento. Un valor cotizado en nuestra herramienta no garantiza que el valor provisto es el valor final de su seguro. En los autoexpedibles se perfecciona el contrato con la aceptación de la propuesta que se le presenta, por ende el precio es definitivo. No se garantiza que la aseguradora acepte la venta de cualquier tipo de seguro al precio presentado en la plataforma. La aceptación y suscripción de cualquier seguro cotizado en <strong>Asegurame</strong> está a la completa discreción de la aseguradora, Asegurame hace la comparación de precios en base a los datos proporcionados por el cliente y la información entregada por las aseguradoras. Las condiciones finales de suscripción de una póliza se realizan de acuerdo a las políticas establecidas por cada aseguradora.
        </p>

        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Licencia
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          <strong>Asegurame</strong> a través de su sitio web concede una licencia para que los usuarios naveguen y comparen precios de seguros en este sitio web de acuerdo a los Términos y Coindiciones que se describen en este documento.
        </p>


        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Propiedad
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El usuario no está autorizado para declarar o realizar acciones relativos a la propiedad intelectual o uso distinto a los objetivos de <strong>Asegurame</strong>, ni a ninguna de nuestras herramientas, servicios, productos o contenido, modificado o sin modificar. Todos los productos y servicios son propiedad de los proveedores del contenido, información, imágenes o fotografías, incluyendo los textos, condiciones o cláusulas proporcionados por las respectivas compañías de seguros. En caso de que no se especifique lo contrario, nuestros servicios se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún momento la empresa será responsable de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Todos los productos y servicios presentados en la plataforma de <strong><strong>www.asegurame.cr</strong></strong> son informados por la Correduría UNISERSE, incluyendo, pero no limitado a la información de seguros, valores de cotización y condiciones. Tenga en cuenta, que las cotizaciones online son resúmenes muy limitados de la información completa que tiene una propuesta de seguros para un usuario en particular. En consecuencia, se requiere de documentación previa y la emisión de la cotización de una propuesta detallada para obtener las condiciones y características de un seguro en particular, a excepción de los seguros categorizados como expedibles. La información proporcionada por <strong>Asegurame</strong> procura ser un resumen completo, exacto y actualizado de las condiciones más relevantes de una propuesta de seguros y los documentos contractuales son enviados directamente por la aseguradora. Sin embargo, no se garantiza dicha integridad, exactitud o vigencia cuando la información es proporcionada por terceros, bajo su propia responsabilidad.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          No serán responsabilidad de <strong>Asegurame</strong>, ni de Correduría UNISERSE, ni de las aseguradoras o proveedores de servicios, los eventuales daños o perjuicios que puedan derivarse del uso inadecuado de este sitio, ni aquellos cuya causa se deba a razones técnicas o de fuerza mayor, así como los producidos por demoras, virus o interrupciones derivados de errores o inexactitudes involuntarios de la información contenida en el sitio web o enviado por correo electrónico.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Los clientes podrán acceder a la información proporcionada por las compañías de seguro debidamente autorizadas para operar en Costa Rica y contar con la asesoría de la Correduría UNISERSE, quienes lo asesorarán en la elección de la mejor protección de los riesgos asegurables, según sus necesidades individuales.
        </p>


        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Clave de acceso
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          En todo momento, el usuario es el responsable único y final de mantener en secreto sus claves de acceso.
        </p>

        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Uso no autorizado
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El usuario no está autorizado a colocar uno de nuestras cotizaciones, propuestas, comparaciones y o cualquier otra imagen o contenido, modificado o sin modificar, en un sitio web o ningún otro medio sin nuestra autorización previa.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Los valores y cotizaciones presentadas son exclusivas de las condiciones ofrecidas por la plataforma <strong>Asegurame</strong>. Debido a eso la cotización entregada por la plataforma de <strong>Asegurame</strong> no tiene validez para presentar la información de esa cotización con cualquier aseguradora para exigir los valores o condiciones presentados en esta herramienta. Los seguros son contratos de riesgos muy específicos y contienen cláusulas de evaluación de riesgos directos y colectivos cambiantes y que requiere de análisis actuarial para ser emitidos para cada caso.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Las cotizaciones presentadas a través de la plataforma son para uso exclusivo de los clientes de la plataforma. Para suscribir una póliza de seguros el cliente será atendido por los agentes de <strong>Asegurame</strong>, para seguir los procedimientos administrativos exigidos por la normativa y cada empresa aseguradora respecto del seguro requerido. Ninguna cotización, oferta o propuesta debe considerarse un seguro suscrito, hasta que se hayan completado todos los requisitos que dan origen a una póliza de seguros en una de las empresas aseguradoras autorizadas para operar en Costa Rica. La solicitud de cotización directa a una o varias aseguradas no tiene relación ni debe ser vinculada a la información y o gestiones relacionadas con la plataforma de seguros online.
        </p>

        <h5 className='subtitulo fontPSemiBold color-primary xl:mb-0'>
          Privaciad
        </h5>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          La herramienta <strong>www.asegurame.cr</strong> garantiza que la información personal que usted envía serán guardados de manera segura. Usted acepta que <strong>Asegurame</strong> debe guardar sus datos personales para cotizar su seguro de manera rápida y eficiente. También permite y autoriza a que <strong>Asegurame</strong> envíe correos electrónicos promocionales ocasionalmente y que se utilice como mecanismo de comunicación entre la plataforma y un usuario. La suscripción a boletines de correos electrónicos publicitarios es voluntaria al momento de cotizar un seguro y podría darse de baja en cualquier momento que lo solicite.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          La respuesta a las preguntas formuladas durante la fase recolección de datos, es de carácter obligatoria. La no entrega de la información solicitada puede ocasionar el rechazo de la solicitud de servicios a <strong>Asegurame</strong>, Uniserse y/o de las entidades aseguradoras que ofrezcan sus servicios o productos.
        </p>


        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Declaro que la información provista en los formularios y documentos de respaldo es fiel y verdadera, por tanto, acepto que cualquier omisión o falsedad en los datos suministrados causaría la ruptura de la relación de intermediación de seguros; de igual manera me comprometo a mantener informado a asegurame.com, Uniserse y la aseguradora sobre los cambios que puedan presentarse a futuro, con respecto a dicha información, mientras exista una relación comercial.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          El firmante acepta y reconoce que ha sido informado sobre el fin del tratamiento de sus datos personales y sobre los intervinientes en dichos procesos. En caso de tener dudas o preguntas sobre el procesamiento de la información personal suministrada, o en caso de que desee ejercer alguno de sus derechos de acceso, rectificación, cancelación o eliminación sobre la información personal suministrada, podrá comunicarse a través del teléfono: <strong>(506) 8647-3773,</strong> al correo electrónico <strong>info@asegurame.cr</strong> o bien directamente en las instalaciones de la compañía en <strong>Costado SurEste del Puente Juan Pablo II, Edificio UNISERSE, La Uruca, San José, Costa Rica.</strong>
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          Además autorizo a <strong>Asegurame</strong> y Uniserse para acceder a los sistemas de empresas comercializadoras de bases de datos o cualquier otra similar, para verificar datos, información personal, sean datos personales de acceso irrestricto o de acceso restringido, incluyendo dentro de estos la fotografía del documento de identidad y otros, permitidos de conformidad con la Ley de Protección de la Persona frente al Tratamiento de sus Datos Personales, Ley Nº8968; así como bases de datos de entidades públicas.
        </p>

        <p className="contenido fontPRegular text-left xl:mt-5 text-justify">
          <strong>Asegurame</strong> se reserva los derechos de cambiar o modificar estos términos sin previo aviso. En este caso se comunicará cualquier cambio en las condiciones y uso de la plataforma.
        </p>
      </div>
    </article>
  );
};

export default TerminosCondiciones;
