export function getFechasPago(formaPago) {
  const now = new Date();
  const fechas = [];
  let incremento;

  // Definir el incremento según la forma de pago
  switch (formaPago) {
    case 'Mensual':
      incremento = 1;
      break;
    case 'Trimestral':
      incremento = 3;
      break;
    case 'Semestral':
      incremento = 6;
      break;
    default:
      return [];
  }

  // Generar fechas futuras basadas en el incremento
  for (let i = 1; i <= 12; i++) {
    // Generar 12 fechas
    const nuevaFecha = new Date(now);
    nuevaFecha.setMonth(now.getMonth() + incremento * i);

    if (nuevaFecha.getDate() !== now.getDate()) {
      nuevaFecha.setDate(0);
    }

    const dia = nuevaFecha.getDate();
    const mes = nuevaFecha.toLocaleString('es-ES', { month: 'short' });
    const anio = nuevaFecha.getFullYear();

    fechas.push(`${dia}-${mes}-${anio}`);
  }

  // Devolver las fechas seleccionadas según la forma de pago
  let fechasSeleccionadas = [];
  if (formaPago === 'Mensual') {
    fechasSeleccionadas = [
      { label: fechas[0], pago: 2 },
      { label: fechas[1], pago: 3 },
      { label: '...', pago: 0 },
      { label: fechas[fechas.length - 1], pago: 12 }, // Último pago
    ];
  } else if (formaPago === 'Semestral') {
    fechasSeleccionadas = [{ label: fechas[0], pago: 2 }];
  } else if (fechas.length >= 3) {
    fechasSeleccionadas = [
      { label: fechas[0], pago: 2 },
      { label: fechas[1], pago: 3 },
      { label: fechas[2], pago: 4 },
    ];
  }

  return fechasSeleccionadas;
}
