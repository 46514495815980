import { useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

// Components
import {
  ButtonCustom,
  TextCustom,
  TextInputCustom,
  AlertCustom,
  SelectCustom,
  TextInputCustomMask,
  Preguntita,
  CheckBoxCustom,
} from '../../atoms';

import {
  ModalCargoAutomatico,
  ModalFacturacionEmpresarial,
} from '../../organisms';

// Hooks
import { useApp, useCotizador, useForm } from '../../../hooks/others';

// Validaciones
import { formValidMetodoPago } from '../../../core/validations/formValidMetodoPago';

//assets
import VisaMasterCard from '../../../assets/images/Cotizador/Visa_Mastercard.svg';
import { ReactComponent as LogoUniserse } from '../../../assets/images/Logos/UNISERSELogo.svg';

// Api
import {
  apiRealizarPago,
  apiActualizarOportunidad,
  enviarCorreoVerificacion,
  enviarCorreoFacturacion,
  apiEmitirPoliza,
  apiCrearBitacora,
  apiGetEtapa,
} from '../../../services/apis';

//styles
import './styles.css';

// Utils
import {
  changeQueryParam,
  convertirColones,
  generarParamsOportunidad,
  hashDataTajeta,
  renderDetalleSeguro,
  renderIconoSeguro,
  retornarNombreMoneda,
} from '../../../core/utils';

import { typesValidation } from '../../../common/types';
import { useNavigate } from 'react-router-dom';
import { labelFormasPago } from './utils';

const PasoPago = () => {
  //context
  const {
    paso,
    handleSiguientePaso,
    setDataCotizar,
    dataCotizar,
    plan,
    idOportunidad,
    sumasAseguradas,
    idCotizacion,
    isRecuperandoCotizacion,
    setCargando,
    pasoActual,
    setPasoActual,
    setPasoAnterior,
    setPoliza,
    formasPago,
    formaPagoSelect,
    setFormaPagoSelect,
    primas,
  } = useCotizador();

  const { recaptchaRef } = useApp();

  //useState
  const [nombre, setNombre] = useState('');
  const [numeroTarjeta, setNumeroTarjeta] = useState({
    formatValue: '',
    value: '',
  });
  const [fecha, setFecha] = useState({
    formatValue: '',
    value: '',
  });

  const [colegiado, setColegiado] = useState('');
  const [cvv, setCvv] = useState('');
  const [modal, setModal] = useState(false);
  const [planSelecionado, setPlanSelecionado] = useState({});
  const [formaPago, setFormaPago] = useState('');
  const [moneda, setMoneda] = useState('');
  const [cuotaId, setCuotaId] = useState('');
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [metodoPago, setMetodoPago] = useState({});
  const [modalRequisitos, setModalRequisitos] = useState(false);
  const [intentos, setIntentos] = useState(0);
  const [facturacion, setFacturacion] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [checkColegiado, setCheckColegiado] = useState(false);
  const formaPagoRef = useRef(formaPagoSelect);

  const [errors, setErrors] = useForm({
    nombre: '',
    cedula: '',
    correo: '',
  });

  const [dataFacturacion, setDataFacturacion] = useState({
    nombre: '',
    cedula: '',
    correo: '',
  });

  const [formErrors, setFormErrors] = useForm({
    nombre: '',
    numeroTarjeta: '',
    fecha: '',
    cvv: '',
    colegiado: '',
  });

  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });

  const navigate = useNavigate();
  useEffect(() => {
    formaPagoRef.current = formaPagoSelect;
  }, [formaPagoSelect]);

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso, pasoActual, setPasoActual, setPasoAnterior]);

  useEffect(() => {
    if (paso === 4 && isRecuperandoCotizacion) {
      changeQueryParam('paso', paso);
    }
  }, [paso, isRecuperandoCotizacion]);

  useEffect(() => {
    if (paso === 5) {
      //* colocar un forma de pago por defecto al cargar el componente
      setFormaPago(plan?.forma_pago[0]?.id?._id);
      //* colocar por defecto, tarjeta de credito como metodo de pago
      const metodoPago = plan.metodo_pago.find(
        metodo => metodo.nombre === 'Tarjeta de Crédito',
      )?._id;

      setMetodoPago(metodoPago);
    }
  }, [paso, plan]);

  useEffect(() => {
    if (paso === 5) {
      //buscar el id de cuota
      const cuota = plan?.forma_pago?.find(
        forma => forma?.id?.nombre === formaPago,
      );

      setCuotaId(cuota?.id?._id);
    }
  }, [formaPago, paso, plan]);

  useEffect(() => {
    const total = labelFormasPago(formaPagoRef.current, primas).cuota;
    setTotalAPagar(total);
    setMoneda(totalAPagar?.toString().split('')[0]);
  }, [plan, dataCotizar, formaPago, paso, totalAPagar, primas, formaPagoSelect]);

  const pasoEmision = async () => {
    try {
      setCargando(true);
      const requisitosCodigos = { AEP: '', DDP: '', DDPO: '' };

      let infoPoliza = '';

      const token = await recaptchaRef.current.executeAsync();

      if (dataCotizar.seguro.codigo === 'SEG-ONC') {
        requisitosCodigos.AEP = 'aceptado';
        requisitosCodigos.DDP = 'aceptado';
        requisitosCodigos.DDPO = 'aceptado';
      } else {
        requisitosCodigos.AEP = 'aceptado';
        requisitosCodigos.DDP = 'aceptado';
      }

      if (token) {
        //*generar poliza
        const paramsEmisionPoliza = {
          plan: { _id: plan._id },
          dataPersonal: dataCotizar.datosPersonales,
          dataSeguro: dataCotizar.dataSeguro,
          cotizacion: dataCotizar.cotizacion,
          seguro: dataCotizar.seguro,
          oportunidadId: idOportunidad,
          tokenReCaptcha: token,
          formaPago: formaPagoSelect,
        };

        const { data: dataPoliza, success } =
          await apiEmitirPoliza(paramsEmisionPoliza);

        //* quiere decir que le emision de la poliza se realizo correctamente y luego se avanza al paso de pago
        const formaPago = formaPagoRef.current;
        if (success) {
          let paramsOportunidad = generarParamsOportunidad(
            idOportunidad,
            idCotizacion,
            'En espera la confirmación de pago para finalizar la emisión de la póliza.',
            'COTIZADOR',
            'PAG',
            'EMI',
            {
              poliza: dataPoliza.numeroPoliza,
            },
          );

          paramsOportunidad = {
            ...paramsOportunidad,
            formaPagoSelect: formaPago,
          };

          infoPoliza = dataPoliza.numeroPoliza;

          await apiActualizarOportunidad(paramsOportunidad);
        } //en caso de que la poliza no se realizo correctamente
        else {
          setAlert({
            title: dataPoliza.response.data?.error ?? 'Error',
            description:
              dataPoliza.response.data?.message ??
              'Ha ocurrido un error al emitir la poliza',
            severity: 'error',
          });
          setModal(true);

          const paramsOportunidad = generarParamsOportunidad(
            idOportunidad,
            idCotizacion,
            'Hubo un error al emitir la poliza',
            'ERROR',
            'EMI',
            'EMI',
            requisitosCodigos,
          );

          await apiActualizarOportunidad(paramsOportunidad);
        }
      }

      return infoPoliza;
    } catch (error) {
      console.error(error);
    }
  };

  const aceptacionModal = async resp => {
    const token = await recaptchaRef.current.executeAsync();

    const requisitosCodigos = { AEP: '', DDP: '', DDPO: '' };
    const infoPoliza = await pasoEmision();
    //Verificar el paso de emision termino con exito

    if (infoPoliza) {
      if (resp) {
        setCargando(true);

        //* si acepta la emision de la poliza la etapa se pasa a emision
        //codigos de requisitos

        if (dataCotizar.seguro.codigo === 'SEG-ONC') {
          requisitosCodigos.AEP = 'aceptado';
          requisitosCodigos.DDP = 'aceptado';
          requisitosCodigos.DDPO = 'aceptado';
        } else {
          requisitosCodigos.AEP = 'aceptado';
          requisitosCodigos.DDP = 'aceptado';
        }

        setPoliza(infoPoliza);

        if (token) {
          const anioActual = new Date().getFullYear().toString();
          let total = 0;

          total =
            moneda === '$'
              ? totalAPagar.substring(1)
              : convertirColones(
                  labelFormasPago(formaPagoRef.current, primas).cuota,
                );

          const forma = formaPagoSelect;

          const params = {
            plan: { _id: plan._id },
            dataPersonal: dataCotizar.datosPersonales,
            dataSeguro: dataCotizar.dataSeguro,
            dataTarjeta: {
              numeroTarjeta: hashDataTajeta(numeroTarjeta.value),
              montoTransaccion: hashDataTajeta(total),
              cvv: hashDataTajeta(cvv),
              mesExpiracion: hashDataTajeta(fecha.formatValue.split('/')[0]),
              anioExpiracion: hashDataTajeta(
                `${anioActual.substring(0, 2)}${fecha.formatValue.split('/')[1]}`,
              ),
              moneda: hashDataTajeta(retornarNombreMoneda(moneda)),
              detalleCargo: hashDataTajeta(infoPoliza),
              correoElectronico: hashDataTajeta(
                dataCotizar.datosPersonales.correo,
              ),
            },
            cotizacion: planSelecionado,
            seguro: dataCotizar.seguro,
            formaPago: forma,
            metodoPago: metodoPago,
            oportunidadId: idOportunidad,
            tokenReCaptcha: token,
            numeroIdentidadColegiado: colegiado,
          };
          setModalRequisitos(false);
          setIntentos(intentos + 1);
          const { data, statusCode } = await apiRealizarPago(params);
          const etapa = await apiGetEtapa({ codEtapa: 'PAG' });
          if (data?.success) {
            setIntentos(0);
            if (
              dataFacturacion &&
              Object.keys(dataFacturacion).length > 0 &&
              dataFacturacion.nombre &&
              dataFacturacion.cedula &&
              dataFacturacion.correo
            ) {
              const request = {
                ...dataFacturacion,
                idOportunidad: idOportunidad,
              };

              await enviarCorreoFacturacion(request);

              const mensaje = `Facturacion Empresarial. Nombre de empresa - ${dataFacturacion.nombre}, Cédula - ${dataFacturacion.cedula} y Correo - ${dataFacturacion.correo}.`;

              const params = {
                descripcion: mensaje,
                tipo: 'OTROS',
                oportunidadId: idOportunidad,
                etapaId: etapa.data._id,
              };

              await apiCrearBitacora(params);
            }

            if (dataCotizar.seguro.formasPago) {
              const formaPago = formaPagoRef.current;

              const paramsFormaPago = {
                descripcion: `Forma Pago: ${formaPagoSelect} - cuota ${labelFormasPago(formaPago, primas).cuota}`,
                tipo: 'OTROS',
                oportunidadId: idOportunidad,
                etapaId: etapa.data._id,
              };

              await apiCrearBitacora(paramsFormaPago);
            }

            if (checkColegiado) {
              const mensajeColegiado = `Titular de la tarjeta del colegiado: ${colegiado}`;

              const paramsColegiadoBitacora = {
                descripcion: mensajeColegiado,
                tipo: 'OTROS',
                oportunidadId: idOportunidad,
                etapaId: etapa.data._id,
              };

              await apiCrearBitacora(paramsColegiadoBitacora);
            }

            const paramsOportunidad = generarParamsOportunidad(
              idOportunidad,
              idCotizacion,
              'Se realizó el pago correctamente',
              'COTIZADOR',
              'GAN',
              'PAG',
              {
                totalPagar: labelFormasPago(formaPagoRef.current, primas)
                .primaTotal,
                cuotas: cuotaId,
                valorCuota: labelFormasPago(formaPagoRef.current, primas).cuota,
                formaPagoSeleccionada: formaPagoRef.current,
                metodoPago: metodoPago._id,
                estadoPago: 'realizado',
                HCA: 'aceptado',
              },
            );

            await apiActualizarOportunidad(paramsOportunidad);

            // //* actualizamos la bitacora que esta ganada
            const paramsOportunidadGanada = generarParamsOportunidad(
              idOportunidad,
              idCotizacion,
              'La oportunidad ha sido ganada tras completar todas las etapas del proceso. ¡Felicitaciones al equipo!',
              'COTIZADOR',
              'GAN',
              'GAN',
              {},
            );

            await apiActualizarOportunidad(paramsOportunidadGanada);

            await enviarCorreoVerificacion({ idOportunidad: idOportunidad });

            setDataCotizar({
              ...dataCotizar,
              pago: data.data,
            });

            setAlert({
              title: 'Pago exitoso',
              description: 'Tu pago ha sido realizado con éxito. ¡Gracias!',
              severity: 'success',
            });
            setModal(true);

            setTimeout(() => {
              handleSiguientePaso();
              setModal(false);
            }, 2500);
          } else {
            const paramsOportunidad = generarParamsOportunidad(
              idOportunidad,
              idCotizacion,
              'El pago no se realizó correctamente',
              'COTIZADOR',
              'PAG',
              'PAG',
              {
                totalPagar: labelFormasPago(formaPagoRef.current, primas)
                  .primaTotal,
                valorCuota: labelFormasPago(formaPagoRef.current, primas).cuota,
                cuotas: cuotaId,
                metodoPago: metodoPago._id,
                estadoPago: 'rechazado',
                HCA: 'aceptado',
              },
            );
            await apiActualizarOportunidad(paramsOportunidad);

            setCargando(false);

            if (intentos > 2) {
              setAlert({
                title: 'Transacción Inválida',
                description:
                  'La transacción no puede ser procesada en este momento. Intenta más tarde.',
                severity: 'error',
              });
            } else {
              if (statusCode === 500) {
                navigate('/page-error-500');
              } else if (statusCode === 400) {
                setAlert({
                  title: data.error,
                  description: data.mensaje,
                  severity: 'error',
                });
              }
            }

            setModal(true);
          }
        }
      } else {
        //en caso de que no se acepte el modal
        const paramsOportunidad = generarParamsOportunidad(
          idOportunidad,
          idCotizacion,
          'No aceptó modal de cargo cargo automatico',
          'COTIZADOR',
          'PAG',
          'PAG',
          {
            totalPagar: labelFormasPago(formaPagoRef.current, primas).primaTotal,
            cuotas: '',
            metodoPago: '',
            estadoPago: 'pendiente',
            HCA: 'rechazado',
          },
        );

        await apiActualizarOportunidad(paramsOportunidad);
      }
    }
  };

  const handleSubmit = async () => {
    if (handleValidForm()) {
      const pago = plan.forma_pago.find(forma => forma.id._id === formaPago)?.id
        ?.nombre;

      if (pago === 'Anual') {
        aceptacionModal(true);
      } else {
        setModalRequisitos(true);
      }
    }
  };

  useEffect(() => {
    if (checkColegiado === false) {
      setColegiado('');
      setFormErrors(prev => {
        return {
          ...prev,
          colegiado: '',
        };
      });
    }
  }, [checkColegiado, setFormErrors]);

  const handleValidForm = () => {
    const dataColegiado = {
      value: colegiado,
      numeroIdentificacion: dataCotizar.datosPersonales.numeroIdentificacion,
      flag: checkColegiado,
    };

    const data = {
      nombre,
      numeroTarjeta,
      fecha,
      cvv,
      dataColegiado,
    };
    const responseValid = formValidMetodoPago(data);
    const { isValid, msgValid } = responseValid;
    setFormErrors(msgValid.errors);
    return isValid;
  };

  const handleCheck = () => {
    setFacturacion(!facturacion);
    if (!facturacion) {
      setCheckBox(false);
      setDataFacturacion({});
      setErrors({});
    }
  };

  const handleNumeroTarjeta = values => {
    const { formattedValue, value } = values;
    setNumeroTarjeta({ formatValue: formattedValue, value });
    handleForm('numeroTarjeta', value);
  };

  const handleFechaVencimiento = values => {
    const { formattedValue, value } = values;
    setFecha({ formatValue: formattedValue, value });
    handleForm('fecha', value);
  };

  const handleForm = (value, event) => {
    if (formErrors[value] && event) {
      if (value === 'numeroTarjeta' && event.length < 16)
        setFormErrors({
          ...formErrors,
          [value]: 'Ingresa los 16 dígitos de tu tarjeta',
        });
      else setFormErrors({ ...formErrors, [value]: '' });
    }
  };

  const handleCheckColegiado = () => {
    setCheckColegiado(!checkColegiado);
  };

  const getFactEmp = () => {
    if (isRecuperandoCotizacion) {
      return dataCotizar.factEmp;
    } else {
      return dataCotizar.seguro.factEmp;
    }
  };

  return (
    <>
      <div className="w-[85%] mx-auto mb-10 lg:mb-20 mt-5">
        <AlertCustom
          title={alert.title}
          description={alert.description}
          open={modal}
          setOpen={setModal}
          severity={alert.severity}
        />

        <p className="fontPSemiBold color-primary text-[22px] md:text-[40px] text-center mb-3">
          Paga tu
          <br className="min-[480px]:hidden" />
          {` ${dataCotizar.seguro.nombre}`}
        </p>

        <TextCustom
          text="Ingresa tu tarjeta de crédito o débito para completar tu compra de tu seguro."
          variant="p"
          component="p"
          className="fontPRegular mx-auto md:w-auto color-primary text-center text-[14px] md:text-base"
        />
        <div className=" grid-pago-seguro my-10">
          <div>
            <div
              className={`${
                plan.forma_pago.length > 1 ? 'grid gap-6' : 'bg-card'
              }`}
            >
              <div
                className={`p-7 pb-0 ${plan.forma_pago.length > 1 ? 'bg-card' : ''}`}
              >
                <div className="flex justify-between items-center mb-6 relative">
                  <TextCustom
                    text="Detalle compra"
                    className="color-primary fontPSemiBold text-xl"
                    component="p"
                    variant="p"
                  />
                  <div
                    className={`container-icon icon-${dataCotizar.seguro.codigo}`}
                  >
                    <img
                      src={renderIconoSeguro(dataCotizar.seguro.codigo)}
                      alt={dataCotizar.seguro.codigo}
                    />
                  </div>
                </div>
                <hr className={`-translate-y-4`} />
                <div className={`p-0 md:p-5 md:pb-0 mt-0 md:mt-5`}>
                  <div className="md:space-y-0 grid grid-cols-2 gap-4 gap-y-10 pb-4">
                    {renderDetalleSeguro(
                      dataCotizar.seguro.codigo,
                      dataCotizar,
                      sumasAseguradas,
                    )}
                  </div>
                </div>
                {getFactEmp() && (
                  <>
                    {dataCotizar.seguro.formasPago && (
                      <div className="md:space-y-0 w-full grid grid-cols-2 border-y-2 py-5">
                        <div className="w-36 flex justify-center items-center">
                          <TextCustom
                            text="Selecciona una forma de pago"
                            className="fontPRegular text-xs md:text-base whitespace-pre-line"
                          />
                        </div>
                        <SelectCustom
                          options={formasPago.map(forma => ({
                            id: forma.id,
                            label: forma.label,
                          }))}
                          defaultValue={formaPago}
                          value={formaPagoSelect}
                          setValue={setFormaPagoSelect}
                          borderStyle={true}
                        />
                      </div>
                    )}

                    {formaPagoSelect !== 'Anual' &&
                      dataCotizar.seguro.formasPago && (
                        <>
                          <div className="md:space-y-0 grid grid-cols-2 gap-4 border-y-2 p-5">
                            <div>
                              <TextCustom
                                text={`${labelFormasPago(formaPagoSelect, primas).numeroCuotas} Cuotas de:`}
                                className="font-PRegular whitespace-nowrap text-sm"
                              />
                              <br />
                              <TextCustom
                                text={
                                  labelFormasPago(formaPagoSelect, primas).cuota
                                }
                                className="font-PRegular text-wrap text-sm font-bold"
                              />
                            </div>

                            <div>
                              <TextCustom
                                text="Total: "
                                className="font-PRegular text-wrap text-sm"
                              />
                              <br />
                              <TextCustom
                                text={
                                  labelFormasPago(formaPagoSelect, primas)
                                    .primaTotal
                                }
                                className="font-PRegular text-wrap text-sm font-bold"
                              />
                            </div>
                          </div>
                        </>
                      )}

                    <div className="flex justify-start items-center gap-2 relative top-14">
                      <div>
                        <CheckBoxCustom
                          value={checkBox}
                          setValue={handleCheck}
                        />
                      </div>
                      <div>
                        <span
                          className="fontPRegular text-xs md:text-base cursor-pointer"
                          onClick={handleCheck}
                        >
                          {`Emitir factura a nombre de empresa ${dataFacturacion.nombre ? dataFacturacion.nombre + '.' : ''}`}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {primas.length > 1 ? '' : ''}
              <div
                className={`p-7 mb-4 md:mb-0 md:p-7 ${
                  plan.forma_pago.length > 1 && 'bg-card'
                }`}
              >
                {/* {plan.forma_pago.length > 1 ? (
                  <>
                    <div className="flex flex-col md:flex-row justify-between items-center md:mx-7 lg:mx-0">
                      <div>
                        <TextCustom
                          text="Total de tu compra"
                          component="p"
                          variant="p"
                          className="color-primary fontPSemiBold text-xl mb-3 mt-5 md:mt-0"
                        />
                        <hr />
                      </div>
                      <SelectCustom
                        options={plan?.forma_pago?.map(forma => ({
                          id: forma?.id?._id,
                          label: forma?.id?.nombre,
                        }))}
                        defaultValue={formaPago}
                        value={formaPago}
                        setValue={setFormaPago}
                        className="w-1/2 text-center md:w-36"
                        borderStyle={true}
                        altura="h-12"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-between mt-10 mb-5 md:mb-0">
                      <TextCustom
                        text="Total a pagar:"
                        component="p"
                        variant="p"
                        className="fontPRegular text-xs md:text-base md:ml-6"
                      />
                      <TextCustom
                        text={labelFormasPago().primaTotal}
                        className="fontPBold text-lg md:text-[25px]"
                        component="p"
                        variant="p"
                      />
                      <TextCustom text={`/${formaPagoSelect}`} />
                    </div>
                  </>
                ) : (
                  ''
                )} */}
              </div>
            </div>
            <div className="bg-card mt-5 p-7">
              <div className="flex justify-between items-center gap-5">
                <LogoUniserse className="max-w-[90px] max-h-[20px]  min-[500px]:max-w-[120px] md:max-w-[180px] md:max-h-[40px]" />

                <div className="">
                  <TextCustom
                    text="Con el respaldo de"
                    className="text-black fontPRegular text-[11px]  min-[500px]:text-sm  md:text-[17px]"
                    component="p"
                    variant="p"
                  />
                  <TextCustom
                    text="Correduría de Seguros UNISERSE"
                    className="text-black fontPSemiBold text-[11px] min-[500px]:text-sm  md:text-[17px]"
                    component="p"
                    variant="p"
                  />
                </div>
              </div>
              <hr className="my-3 " />
              <TextCustom
                text={'Para más información contáctanos a: '}
                className="fontPRegular text-[10px] min-[500px]:text-xs  md:text-sm text-black"
              />
              <Link
                to={'https://wa.me/50686473773'}
                target="_blank"
                rel="noopener noreferrer"
                className="color-secondary fontPRegular text-[10px] min-[500px]:text-sm  md:text-sm underline cursor-pointer"
              >
                (506) 8647-3773
              </Link>
            </div>
          </div>
          <div className="bg-card p-7">
            <div className="flex justify-between items-center mb-0 md:mb-4">
              <TextCustom
                text="Método de Pago"
                className="color-primary fontPSemiBold text-xl"
                component="p"
                variant="p"
              />
              <img
                src={VisaMasterCard}
                alt="Imagen logo tarjetas"
                className="w-20 md:w-28"
              />
            </div>
            <hr />
            <form className="pt-10 space-y-9">
              <div>
                <TextCustom
                  text="Nombre del titular de la tarjeta:"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required
                />
                <TextInputCustom
                  type="text"
                  placeholder="Titular de la tarjeta"
                  value={nombre}
                  setValue={setNombre}
                  typesValidation={typesValidation.lettersExtend}
                  msgError={formErrors.nombre}
                  onKeyUp={event => handleForm('nombre', event.target.value)}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Número de tarjeta:"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required
                />
                <PatternFormat
                  value={numeroTarjeta.formatValue}
                  onValueChange={handleNumeroTarjeta}
                  customInput={TextInputCustomMask}
                  placeholder="Número de tarjeta"
                  format="#### #### #### ####"
                  mask="_"
                  msgError={formErrors.numeroTarjeta}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 md:gap-16">
                <div>
                  <TextCustom
                    text="Fecha de vencimiento (MM/AA)"
                    component="p"
                    variant="p"
                    className="fontPRegular text-xs md:text-base  md:w-auto"
                    required
                  />
                  <PatternFormat
                    value={fecha.formatValue}
                    onValueChange={handleFechaVencimiento}
                    customInput={TextInputCustomMask}
                    placeholder="MM/AA"
                    format="##/##"
                    mask="_"
                    msgError={formErrors.fecha}
                    alignError="left"
                    borderStyle={true}
                  />
                </div>
                <div>
                  <div className="flex gap-2">
                      <TextCustom
                      text="Código de seguridad (CVV)"
                      component="p"
                      variant="p"
                      className="fontPRegular text-xs md:text-base  md:w-auto"
                      required
                    />
                    <div className="flex justify-center items-center">
                    <Preguntita
                        titulo={
                          'El código de verificación de tres dígitos se encuentra en la parte trasera de tu tarjeta de crédito/débito.'
                        }
                      />
                    </div>
                  </div>
                  <TextInputCustom
                    type="password"
                    placeholder="***"
                    borderStyle={true}
                    value={cvv}
                    setValue={setCvv}
                    typesValidation={typesValidation.onlyNumber}
                    msgError={formErrors.cvv}
                    onKeyUp={event => handleForm('cvv', event.target.value)}
                    alignError="left"
                    maxLength={3}
                  />
                </div>
              </div>

              {dataCotizar.seguro.formasPago && (
                <div className="flex flex-col ">
                  <div className=" max-sm:justify-center sm:w-full flex gap-2">
                    <div className="justify-center items-center flex">
                        <CheckBoxCustom
                          value={checkColegiado}
                          setValue={handleCheckColegiado}
                        />
                      <span
                        className="whitespace-nowrap fontPRegular md:text-base cursor-pointer xxs:text-xs"
                        onClick={handleCheckColegiado}
                      >
                        El titular de la tarjeta es distinto al colegiado
                      </span>
                    </div>
                    <div className="flex justify-center items-center">
                      <Preguntita
                        titulo={
                          'Al seleccionar un pago por cuotas, es necesario proporcionar el número de identidad del titular de la tarjeta'
                        }
                      />
                    </div>
                  </div>

                  <TextInputCustom
                    type="text"
                    borderStyle={true}
                    placeholder="No. de identidad del titular de la tarjeta"
                    value={colegiado}
                    setValue={setColegiado}
                    msgError={formErrors.colegiado}
                    className={checkColegiado === false ? 'hidden' : ''}
                    onKeyUp={event =>
                      handleForm('colegiado', event.target.value)
                    }
                    maxLength="9"
                  />
                </div>
              )}

              <div className="flex flex-col gap-5 md:flex-row md:justify-between md:items-center  ">
                <div className="flex flex-col items-center md:flex-row md:items-center md:gap-5 md:whitespace-nowrap ">
                  <TextCustom
                    text="Total a pagar:"
                    component="p"
                    variant="p"
                    className="fontPRegular text-xs md:text-base text-center"
                  />
                  <TextCustom
                    text={
                      dataCotizar.seguro.formasPago
                        ? labelFormasPago(formaPagoSelect, primas).cuota
                        : totalAPagar
                    }
                    className="fontPBold text-lg md:text-[25px] md:mr-64 text-center md:text-left"
                    component="p"
                    variant="p"
                  />
                </div>
                <div className="pt-0 md:justify-end text-center md:text-left">
                  <ButtonCustom
                    text={`Comprar`}
                    className={` md:w-36 fontPSemiBold`}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalFacturacionEmpresarial
          open={facturacion}
          setOpen={handleCheck}
          dataFacturacion={dataFacturacion}
          setDataFacturacion={setDataFacturacion}
          formErrors={errors}
          setFormErrors={setErrors}
          setCheckBox={setCheckBox}
        />
        <ModalCargoAutomatico
          open={modalRequisitos}
          setOpen={setModalRequisitos}
          titulo={plan.pasos[1].requisitos[0].nombre}
          aceptacionModal={aceptacionModal}
          contenido="Muchas gracias por confiar en la aseguradora más grande de Centroamérica. Si desea que su seguro se rebaje de forma automática según la frecuencia de pago seleccionada, favor marcar “Sí, acepto”. De lo contrario, para renovar el seguro podrá dirigirse a cualesquiera de nuestras Sedes o Puntos de Servicio en todo el país, comunicarse gratuitamente al teléfono 800-TELEINS (800-8353467), o bien al correo electrónico sedevirtual@grupoins.com. En el INS estamos para servirle."
        />
      </div>
    </>
  );
};

export default PasoPago;
