import axios from 'axios';
import buildRequest from '../buildRequest';
import { typesEndpoint } from '../../../common/types';

export const apiPostCotizar = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.postCotizar}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    idCotizacion: params.cotizacionID,
    plan: params.idPlan,
    datosPersonales: params.dataPersonal,
    idOportunidad: params.oportunidadID,
    numPlanes: params.numPlanes
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiPostPlan = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.postPlan}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    plan_id: params.idPlan,
    oportunidadId: params.idOportunidad,
    planesCotizados: params.planesCotizados,
    valorPrimaTotal: params.valorPrimaTotal,
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    // dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};

export const apiEmitirPoliza = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.emitirPoliza}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const request = {
    plan: params.plan,
    datosPersonales: params.dataPersonal,
    oportunidadId: params.oportunidadId,
    tokenReCaptcha: params.tokenReCaptcha,
    formaPago: params.formaPago
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiRealizarPago = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.realizarPago}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const request = {
    plan: params.plan,
    datosPersonales: params.dataPersonal,
    dataSeguro: params.dataSeguro,
    dataTarjeta: params.dataTarjeta,
    cotizacion: params.cotizacion,
    seguro: params.seguro,
    formaPago: params.formaPago,
    metodoPago: params.metodoPago,
    oportunidadId: params.oportunidadId,
    tokenReCaptcha: params.tokenReCaptcha,
    numeroIdentidadColegiado : params.numeroIdentidadColegiado
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error.response?.data;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};

export const apiEnviarResumenCotizacion = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.resumenCorreo}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const request = {
    id: params.cotizacionId,
  };

  try {
    const response = await axios[method](url, buildRequest(request));
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiRecuperarCotizacion = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.recuperarCotizacion}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const request = {
    idCotizacion: params.cotizacionId,
  };

  try {
    const response = await axios[method](url, buildRequest(request));
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
