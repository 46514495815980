import React from 'react';

// Components
import { DialogCustom } from '../../templates';

// Assets
import { ReactComponent as WowImage } from '../../../assets/icons/Alerts/Modal-Viaje-Adicional.svg';

// Css
import './style.css';
const AlertDiasGratis = ({
  open = false,
  severity = 'info',
  size = '380px',
  setOpen = () => null,
  dias = 0,
}) => {
  return (
    <DialogCustom open={open} setOpen={setOpen} className="modal-promocion absolute">
      <div className="flex justify-center sm:justify-end ">
        <div className="flex w-[95%] p-0 max-sm:p-5 justify-end md:w-full">
          <div
            className="sm:min-w-[570px] bg-primary rounded-[20px]"
            style={{
              width: size,
            }}
          >
            <div
              className="justify-between h-auto xxs:h-auto md:h-[16.5rem] flex items-center"
              style={{
                borderRadius: '20px',
              }}
            >
              <div
                className="flex flex-col  gap-3 p-2 md:flex-row"
                style={{ cursor: 'pointer' }}
              >
                <div
                  className="alert-icon border-none justify-center min-w-[12rem] text-contrast-content bg-primary rounded-[20px]"
                  id={severity}
                >
                  <WowImage className="w-[200px] h-[200px]" />
                </div>
                <div className="text-base flex flex-col justify-center px-2 pb-0 max-sm:pb-4 md:mt-0">
                  <p className="fontPSemiBold text-contrast-content text-xl text-center md:text-left">
                    ¡Seguro optimizado!
                  </p>
                  <div className="fontPRegular text-contrast-content text-sm mt-3 text-center md:text-left">
                    <span className="text-center md:text-left">
                      Tu seguro ha sido optimizado gracias a nuestro algoritmo y
                      por el mismo precio disfrutarás{' '}
                      <br className="hidden md:block" /> de {dias} días
                      adicionales de cobertura.{' '}
                      <br className="hidden md:block" /> ¡En hora buena!
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="mr-4 mt-5 absolute top-0 right-0">
                <IconButtonCustom
                  onClick={handleClose}
                  size={14}
                  typeColorHover={'var(--color-neutral)'}
                  icon={<AlertCloseIcon fill={'var(--color-contrast-content)'} />}
                  padding="6px"
                  typeColor={renderTypeColor()}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </DialogCustom>
  );
};

export default AlertDiasGratis;
