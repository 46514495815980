import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';

// Styles
import './style.css';

const Error404 = () => {
  const navigate = useNavigate();

  // Assets
  const Error404Image =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Errors/Error404.webp';

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="contenedor-page-error flex justify-center items-center h-screen w-screen flex-col bg-white fixed top-0 gap-5">
      <img src={Error404Image} alt="error404" className="img-404" />
      <TextCustom
        text="ERROR 404"
        className="fontPMedium color-secondary text-xl pt-1"
      />
      <TextCustom
        text="Parece que estás perdido"
        variant="h2"
        className="fontPBold color-primary"
      />
      <TextCustom
        text="Te llevaremos de nuevo al inicio."
        className="fontPRegular"
      />
      <ButtonCustom
        text="Regresar"
        height="auto"
        width="200px"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default Error404;
