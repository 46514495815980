// Components
import { ButtonCustom, LoaderCotizar, TextCustom } from '../../atoms';
import HelpIcon from '@mui/icons-material/Help';

// Assets
import Logo from '../../../assets/images/Logos/INSLogo.svg';

// Hooks
import { useCotizador } from '../../../hooks/others';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { ModalCobertura, ModalControlValidacion } from '../../organisms';

import { apiEncontrarPersonaListaControl } from '../../../services/apis/Lista Control/listaControl';

//Functions
import { renderContactarControlValidacion } from '../../../common/constants/textoModales';

const CardResultadoMovil = ({
  idPlan = '',
  index = '',
  pasoSiguiente = () => null,
  coberturas = [],
  error = false,
  all = [],
}) => {
  const { dataCotizar, formaPagoSelect } = useCotizador();
  const [modalCobertura, setModalCobertura] = useState(false);
  const [detalleCobertura, setDetalleCobertura] = useState('');
  const [modalValidacion, setModalValidacion] = useState(false);

  let data = '';

  const existeData = () => {
    const newData = all.find(dat => dat._id === idPlan)?.data;
    if (newData) {
      data = newData;
      return true;
    } else {
      return false;
    }
  };

  const handleCobertura = cobertura => {
    setModalCobertura(true);
    setDetalleCobertura(cobertura);
  };

  const onClick = async () => {
    const identificacion = dataCotizar.datosPersonales.numeroIdentificacion;
    let puedeComprar = true;

    const respuesta = async () => {
      const resp = await apiEncontrarPersonaListaControl({
        id_persona: identificacion,
      });
      return resp;
    };
    const resp = await respuesta();

    if (resp.data.no_cotizar) {
      puedeComprar = false;
    } else {
      puedeComprar = true;
    }

    if (puedeComprar) {
      pasoSiguiente(data, idPlan);
    } else {
      setModalValidacion(true);
    }
  };

  const selectFormaPago = (data, formaPagoSelect) => {
    if (data && data.primas) {
      const objectPrima = data.primas.find(
        item => item.frecuenciaPago === formaPagoSelect,
      );
      return objectPrima
        ? {
            frecuenciaPago: objectPrima.frecuenciaPago,
            primaTotal: objectPrima.primaTotal,
            montoPrima: objectPrima.montoPrima,
          }
        : null;
    }
    return null;
  };

  return (
    <>
      <div
        className={`bg-card rounded-md px-4 pt-0 h-full relative ${
          existeData() === false ? 'min-h[300px]' : 'h-full'
        }`}
        style={{
          width: '265px',
          display: error ? 'flex' : 'inline-block',
          background: error ? '#F8FBFD' : 'var(--color-contrast-content)',
          border: error ? '1px solid border-primary-200' : 'none',
          justifyContent: error && 'center',
          alignItems: error && 'center',
        }}
      >
        {!error ? (
          existeData() === false ? (
            <LoaderCotizar
              type="full"
              className={`texto-plan-slider-${index}`}
            />
          ) : (
            <>
              {all[index]?.data?.recomendado ? (
                <div className="bg-primary rounded-t-md absolute w-full left-0 py-1 px-2">
                  <span className=" text-contrast-content fontPRegular text-sm">
                    Plan {data.orden ? data.orden : index + 1} (Recomendado)
                  </span>
                </div>
              ) : (
                <div className="bg-primary-100 rounded-t-md absolute w-full left-0 py-1 px-2">
                  <span className=" text-primary fontPSemiBold text-sm">
                    Plan {data.orden ? data.orden : index + 1}
                  </span>
                </div>
              )}

              {dataCotizar.seguro.formasPago ? (
                <>
                  <div className="mt-14 pt-1 text-center border-b sticky top-16 bg-white z-50">
                    <img
                      src={Logo}
                      alt="Imagen de aseguradora"
                      width={150}
                      className="mx-auto"
                    />
                    <TextCustom
                      text={data.codigoComercial}
                      component="p"
                      className="fontPRegular color-primary text-xs -mt-[5px]"
                    />

                    <div className="pb-[50px]">
                      <TextCustom
                        text={selectFormaPago(data, formaPagoSelect).primaTotal}
                        component="p"
                        className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                      />
                      <TextCustom
                        text="Prima total"
                        component="p"
                        className="pt-0 fontPRegular text-base"
                      />
                      <TextCustom
                        text="(IVA Incluido)"
                        component="p"
                        className="pt-0 fontPRegular text-xs color-outline"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10">
                  <img
                    src={Logo}
                    alt="Imagen de aseguradora"
                    width={150}
                    className="mx-auto"
                  />
                  <TextCustom
                    text={data.codigoComercial}
                    component="p"
                    className="fontPRegular color-primary text-xs -mt-[5px]"
                  />

                  {data?.precios?.map(
                    (precio, index) =>
                      precio?.codigo === 'A' && (
                        <div className="pb-[25px]" key={index}>
                          <TextCustom
                            text={precio?.valor}
                            component="p"
                            className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                          />
                          <TextCustom
                            text="Prima total"
                            component="p"
                            className="pt-0 fontPRegular text-base"
                          />
                          <TextCustom
                            text="(IVA Incluido)"
                            component="p"
                            className="pt-0 fontPRegular text-xs color-outline"
                          />
                        </div>
                      ),
                  )}
                </div>
              )}
              {dataCotizar.seguro.formasPago &&
              formaPagoSelect !== 'Anual' ? (
                <div className="border-b pb-5 text-center mt-4 h-12">
                  <TextCustom
                    text={`Cuota ${formaPagoSelect}`}
                    className="color-ligth-gray fontPRegular text-sm"
                    component="p"
                    variant="p"
                  />

                  <div>
                    <TextCustom
                      text={
                        selectFormaPago(data, formaPagoSelect)?.montoPrima || ''
                      }
                      className="fontPSemiBold text-sm"
                      component="p"
                      variant="p"
                    />
                  </div>
                </div>
              ) : null}
              {coberturas.map((cobertura, index) => (
                <div
                  className={`p-2 flex justify-between items-center ${
                    index + 1 !== coberturas.length && 'border-b'
                  }`}
                  key={index}
                >
                  <TextCustom
                    text={`${cobertura.nombre}`}
                    component="div"
                    className={`fontPRegular text-[0.8rem]`}
                  />
                  {cobertura?.detalle && (
                    <div
                      className="cursor-pointer "
                      onClick={() => handleCobertura(cobertura)}
                    >
                      <HelpIcon
                        fontSize="small"
                        sx={{
                          fill: 'var(--color-secondary)',
                          zIndex: 10
                        }}
                      />
                    </div>
                  )}
                  <TextCustom
                    text={
                      cobertura.codigo === '54J'
                        ? 'Hasta ' + data.coberturas[index]?.descripcion
                        : cobertura.codigo === '77P' ||
                            cobertura.codigo === '78O'
                          ? 'Amparado'
                          : data.coberturas[index]?.descripcion
                    }
                    component="div"
                    className={`fontPSemiBold text-[0.8rem]`}
                  />
                </div>
              ))}
              <div className="text-center py-10">
                <ButtonCustom
                  text={`${data?.pasos ? 'Comprar' : 'Solicitar'}`}
                  width="160px"
                  className="fontPSemiBold"
                  onClick={onClick}
                />
              </div>
              <Link
                to={data?.linkCondiciones}
                className="fontPRegular color-primary text-sm flex justify-center underline cursor-pointer"
                target="_blank"
              >
                Condiciones Generales
              </Link>
            </>
          )
        ) : (
          <div className="color-primary text-xl fontPSemiBold text-center">
            Resultado <br /> no disponible
          </div>
        )}
      </div>
      <ModalCobertura
        open={modalCobertura}
        setOpen={setModalCobertura}
        titulo={detalleCobertura?.nombre}
        cobertura={detalleCobertura?.detalle}
      />

      <ModalControlValidacion
        open={modalValidacion}
        setOpen={setModalValidacion}
        titulo="¡Verificación necesaria!"
        contenido="En este momento no podemos proceder debido a una revisión de nuestros registros"
        contactar={renderContactarControlValidacion()}
      />
    </>
  );
};

export default CardResultadoMovil;
