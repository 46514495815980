import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

// Componentes
import DatePickerCustom from '../DatePickerCustom';
import TextCustom from '../TextCustom';
import MensajeError from '../MensajeError';

// styles
import './style.css';

const DateFechas = ({
  errorAlert = false,
  value = null,
  setValue = () => null,
  msgError = '',
  alignError = '',
}) => {
  const [fechaSalida, setFechaSalida] = useState();
  const [fechaRegreso, setFechaRegreso] = useState();
  const [fechaError, setFechaError] = useState(errorAlert);

  useEffect(() => {
    if (value) {
      const [fechaSalida, fechaRegreso] = value.split('-');

      const formateoFechaSalida = dayjs(fechaSalida, 'DD/MM/YYYY').toDate();
      const formateoFechadRegreso = dayjs(fechaRegreso, 'DD/MM/YYYY').toDate();

      setFechaSalida(formateoFechaSalida);
      setFechaRegreso(formateoFechadRegreso);
    }
  }, [value]);

  useEffect(() => {
    handleValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaSalida, fechaRegreso]);

  const handleFechaSalida = val => {
    setFechaSalida(val);
  };

  const handleFechaRegreso = val => {
    setFechaRegreso(val);
  };

  const handleValid = () => {
    if (!fechaSalida || !fechaRegreso) {
      setFechaError(true);
    } else {
      let fechaS = dayjs(fechaSalida).format('MM/DD/YYYY');
      let fechaR = dayjs(fechaRegreso).format('MM/DD/YYYY');

      if (dayjs(fechaS).isSame(fechaR) || dayjs(fechaS).isAfter(fechaR)) {
        setFechaError(true);
      } else {
        setValue(
          dayjs(fechaSalida).format('DD/MM/YYYY') +
            '-' +
            dayjs(fechaRegreso).format('DD/MM/YYYY'),
        );
        setFechaError(false);
      }
    }
  };

  return (
    <>
      <div
        className="grid grid-fechas"
        style={{ maxWidth: 800, width: '90%' }}
      >
        <div>
          <TextCustom
            text="Salida:"
            component="p"
            variant="p"
            className="fontPSemiBold text-xs lg:text-xl text-left md:text-center md:mb-3 text-primary-content"
          />
          <DatePickerCustom
            minDate={new Date()}
            value={fechaSalida}
            setValue={handleFechaSalida}
            errorAlert={!fechaSalida ? errorAlert : fechaError ?? false}
            placeholderText="Tu fecha de salida"
          />
        </div>
        <div>
          <TextCustom
            text="Regreso:"
            component="p"
            variant="p"
            className="fontPSemiBold text-xs lg:text-xl text-left md:text-center md:mb-3 text-primary-content"
          />
          <DatePickerCustom
            minDate={new Date()}
            value={fechaRegreso}
            setValue={handleFechaRegreso}
            errorAlert={!fechaRegreso ? errorAlert : fechaError ?? false}
            placeholderText="Tu fecha de regreso"
          />
        </div>
      </div>
      {msgError && <MensajeError mensaje={msgError} alignError={alignError} />}
    </>
  );
};

export default DateFechas;
