import { typesValidation } from '../../../common/types';
import { validInputEmail } from '../../../core/validations';
import { ButtonCustom, TextCustom, TextInputCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogContent } from '@mui/material';
import { useEffect } from 'react';

const ModalFacturacionEmpresarial = ({
  open = false,
  setOpen = () => null,
  dataFacturacion = {},
  setDataFacturacion = () => null,
  formErrors = {},
  setFormErrors = () => null,
  setCheckBox = () => null,
}) => {
  const FacturacionIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/facturacion-empresarial.png';

  const handleChange = (value, fieldName) => {
    setDataFacturacion(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: '',
    }));
  };

  const handleSubmit = () => {
    let hasError = false;
    let newErrors = {};

    if (!dataFacturacion.nombre) {
      newErrors.nombre = 'El nombre de la empresa es requerido';
      hasError = true;
    }
    if (!dataFacturacion.cedula) {
      newErrors.cedula = 'El número de cédula jurídica es requerido';
      hasError = true;
    }
    if (!dataFacturacion.correo) {
      newErrors.correo = 'El correo electrónico es requerido';
      hasError = true;
    }

    if (
      dataFacturacion.correo &&
      !validInputEmail(dataFacturacion.correo, typesValidation.validateEmail)
    ) {
      newErrors.correo = 'Formato de correo electrónico no válido.';
      hasError = true;
    }

    setFormErrors(newErrors);

    if (!hasError) {
      setOpen(false);
      setCheckBox(true);
    }
  };

  return (
    <DialogCustom open={open} setOpen={setOpen} >
      <DialogContent className='max-xs:overflow-x-hidden'>
        <div className="flex gap-14 max-md:gap-5 items-center h-fit p-5 max-md:p-0 max-md:flex-col ">
          <div className="flex justify-center items-center w-72">
            <img
              src={FacturacionIcon}
              alt="Imagen Facturacion Empresarial"
              className="w-full h-fit max-w-xs min-w-24 mb-14  max-md:mb-5  max-md:max-w-8   "
            />
          </div>
          <div className="flex flex-col w-96 max-md:max-w-60 max-md:items-center space-y-5">
            <TextCustom
              text="Facturación Empresarial"
              className="text-left max-md:text-center whitespace-nowrap text-3xl text-primary font-[Poppins-Bold] max-md:text-wrap max-md:text-xl  md:mt-9   "
            />

            <div>
              <TextCustom
                text={'Nombre de la empresa'}
                className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base font-[Poppins-Regular] max-md:text-sm max-xs:text-sm max-xs:text-[10px]"
                required={true}
          
              />

              <TextInputCustom
                placeholder="Nombre de la empresa"
                borderStyle={true}
                type="text"
                value={dataFacturacion.nombre}
                setValue={value => handleChange(value, 'nombre')}
                msgError={formErrors.nombre}
                className='max-xs:w-44'
              />
            </div>

            <div>
              <TextCustom
                text={'Número de cédula jurídica'}
                className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base font-[Poppins-Regular] max-md:text-sm max-xs:text-[10px]"
                required={true}
              />
              <TextInputCustom
                placeholder="Número de cédula"
                borderStyle={true}
                type="text"
                value={dataFacturacion.cedula}
                setValue={value => handleChange(value, 'cedula')}
                msgError={formErrors.cedula}
                maxLength="15"
                className='max-xs:w-44'
              />
            </div>

            <div>
              <TextCustom
                text={'Correo electrónico de la empresa'}
                
                className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base font-[Poppins-Regular] max-md:text-sm max-xs:text-[10px]"
                required={true}
              />
              <TextInputCustom
                placeholder="Correo electrónico"
                borderStyle={true}
                type="email"
                value={dataFacturacion.correo}
                setValue={value => handleChange(value, 'correo')}
                msgError={formErrors.correo}
                className='max-xs:w-44 '
              />
            </div>

            <div className="flex justify-start pt-1 max-xs:justify-center">
              <ButtonCustom
                text="Guardar"
                typeColor="general"
                onClick={handleSubmit}
                width="210px"
                height="40px"
                className="text-xs sm:text-sm xsm:text-[15px] md:text-base whitespace-nowrap max-xs:w-44"
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogCustom>
  );
};

export default ModalFacturacionEmpresarial;
