import { Link } from 'react-router-dom';
import { TextCustom } from '../../atoms';

// Hooks
import { useApp } from '../../../hooks/others';

export const renderTexto = {
  'SEG-DA': `Elimina tu participación al sufrir un accidente.`,
  'SEG-V': 'Disfruta sin estrés tus viajes, protegido en todo momento.',
  'SEG-AEG': 'Un escudo para enfrentar el diagnóstico de padecimientos.',
  'SEG-ONC': 'Evita preocupaciones económicas en tratamiento oncológico.',
};

export const urlSeguro = {
  'SEG-DA': '/deducible-vehiculo',
  'SEG-V': '/seguro-viaje',
  'SEG-AEG': '/enfermedades-graves',
  'SEG-ONC': '/seguro-oncologico-plus',
  'SEG-VID': '/seguro-vida-plus',
};

const DropMenuOptions = () => {
  const { seguros } = useApp();

  //assets
  const AsegurameMedico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Small.webp';
  const AsegurameVehiculo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Small.webp';
  const AsegurameViaje =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Small.webp';
  const IconoOncologico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
  const IconoVidaPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Icono-Small.webp';

  const renderImagen = {
    'SEG-DA': (
      <img src={AsegurameVehiculo} alt="AsegurameVehiculo" className="img-da" />
    ),
    'SEG-V': (
      <img src={AsegurameViaje} alt="AsegurameViaje" className="img-v" />
    ),
    'SEG-AEG': (
      <img src={AsegurameMedico} alt="AsegurameMedico" className="img-aeg" />
    ),

    'SEG-ONC': (
      <img
        src={IconoOncologico}
        alt="AsegurameOncologico"
        className="img-oncologico"
      />
    ),
    'SEG-VID': (
      <img
        src={IconoVidaPlus}
        alt="AsegurameVidaPlus"
        className="img-vidaplus"
      />
    ),
  };


  return (
    <div
      className={`grid gap-4 xl:gap-4 justify-center ${
        seguros.length >= 5
          ? 'lg:grid-cols-4 xlm:grid-cols-5 '
          : 'grid-cols-4'
      }`}
    >
      {seguros.map((seguro) => (
        <Link
          to={urlSeguro[seguro.codigo]}
          key={seguro.codigo}
          
          className={`border-r-2  ${seguros.length >= 5  ? 'xlm:[&:nth-child(5n)]:border-none lg:max-xlm:[&:nth-child(4n)]:border-none' : 'lg:max-xlm:[&:nth-child(4n)]:border-none xlm:[&:nth-child(4n)]:border-none' } `}

        >
          <div className="flex gap-4 items-center link-seguros size-full">
            {renderImagen[seguro.codigo]}
            <div className="flex flex-col justify-center">
              <TextCustom
                text={seguro.nombre.replace('#', '')}
                className="font-bold color-primary text-sm fontPRegular mt-4"
              />
              <p className="color-primary-content text-xs fontPRegular mt-2">
                {renderTexto[seguro.codigo]}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
  
};

export default DropMenuOptions;
