import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { metaData } from '../../common/constants';
import HelpIcon from '@mui/icons-material/Help';

export const convertToMarkdownBold = (text = '', className = '') => {
  const bold = /\*(.*?)\*/gm;
  let html = text.replace(bold, '<span class="color-secondary">$1</span>');
  html = html.replace(/\n/g, '<br class="salto-linea">');
  return `<span class="markdown ${className}">${html}</span>`;
};

export const convertCoberturas = (text = '') => {
  // Condición 1: Agregar • al inicio si el texto lleva -
  const withBullet = /-([^-]+)-/g;
  let html = text.replace(
    withBullet,
    '<p class="fontPRegural"><span class="mx-3 fontPBold">•</span>$1</p>',
  );

  // Condición 2: Reemplazar \\n con <br />
  html = html.replace(/\\n/g, '<br />');

  // Condición 3: Agregar formato bold si el texto lleva * al inicio y final
  const boldText = /\*([^*]+)\*/g;
  html = html.replace(boldText, '<span class="fontPBold">$1</span>');

  return `<div class="text-modal-cobertura">${html}</div>`;
};

export const textBlog = (text = '') => {
  const bold = /\*(.*?)\*/gm;
  let html = text.replace(bold, '<b>$1</b>');
  return `${html}`;
};

export const saltoLinea = (text = '') => {
  const html = text.replace(/\\n/g, `<br className="salto-linea">`);
  return `${html}`;
};

export const addSaltoLinea = (text = '') => {
  let html = text.replace('#', '');
  return html;
};

export const fontBoldDetalleCotizacion = (
  detalle,
  index,
  total,
  promocion,
  funcionPromocion = () => null,
) => {
  return (
    <div className={`relative flex ${index === 0 ? 'max-sm:separador' : 'max-sm:separador md:separador'} w-auto`}>
      <p
        className={`md:texto-detalle max-md:ml-2.5 max-md:rounded-[8px] max-md:p-[5px] color-primary fontPRegular flex gap-1 text-[12px] md:text-[16px] text-nowrap ${
          detalle?.split(':')[0] === 'Placa' && 'uppercase'
        } ${
          promocion.promocionDisponible &&
          (detalle?.split(':')[0] === 'Regreso' ||
            detalle?.split(':')[0] === 'Tiempo') &&
          'fondo-promocion'
        } ${total === index + 1 && promocion && 'mr-0'}`}
      >
        <span
          className={`font-bold   ${
            detalle?.split(':')[0] === 'Placa' && 'capitalize'
          } `}
        >
          {detalle.split(':')[0] === 'Genero'
            ? 'Género'
            : detalle.split(':')[0]}
          :
        </span>
        <span className="flex gap-2">{detalle?.split(':')[1]}</span>
      </p>
      {total === index + 1 && promocion.promocionDisponible && (
        <>
          <div
            className="w-8"
          >
          </div>
          <div
            onClick={funcionPromocion}
            className="absolute cursor-pointer flex items-center z-10 mr-3.5 right-0 top-0 bottom-0"
          >
            <HelpIcon
              fontSize="small"
              sx={{
                fill: '#0099B7',
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const obtenerClientID = () => {
  if (Cookies.get('_ga')) {
    const stringCookie = Cookies.get('_ga');

    if (stringCookie == null) {
      return stringCookie;
    } else {
      const arregloCookie = stringCookie.split('.');
      const clientID = arregloCookie[2] + '.' + arregloCookie[3];
      return clientID;
    }
  }
};

export const hashDataTajeta = dato => window.btoa(dato);

export const retornarNombreMoneda = simboloMoneda => {
  switch (simboloMoneda) {
    case '$':
      return 'USD';

    default:
      return 'CRC';
  }
};

export const calcularEdad = edadInput => {
  const fechaNacimiento = dayjs(edadInput, 'DD/MM/YYYY');
  const hoy = dayjs();
  const diferenciaEnDias = hoy.diff(fechaNacimiento, 'day');

  if (diferenciaEnDias > 0 && diferenciaEnDias < 30) {
    return diferenciaEnDias + ' días';
  } else if (diferenciaEnDias >= 30 && diferenciaEnDias < 365) {
    return hoy.diff(fechaNacimiento, 'month') + ' meses';
  } else if (diferenciaEnDias <= 0) {
    return '';
  } else {
    const edad = hoy.diff(fechaNacimiento, 'year').toString();

    return edad + ' años';
  }
};

export const formatearDatosFormulario = (valor, tipoInput) => {
  switch (tipoInput) {
    case 'primerNombre':
    case 'segundoNombre':
    case 'primerApellido':
    case 'segundoApellido':
      return valor.replace(/[0-9<>,.?/:;"'|\]}[{+=\-_)(*&^%$#@!~`\n\\]/g, '');

    case 'edad':
      //return valor.replace(/[^0-9]/g, '');
      return;
    default:
      return valor;
  }
};

export const formatearFecha = (fechaData, formartForBloc = false) => {
  if (formartForBloc) {
    return dayjs(fechaData).locale('es').format('DD [de] MMMM, YYYY');
  }

  return dayjs(fechaData, 'DD/MM/YYYY')
    .locale('es')
    .format('DD [de] MMMM, YYYY');
};

export const formatUrlNota = titulo => {
  const urlSlug = titulo.split(' ').join('-').toLowerCase();
  return urlSlug;
};

export const changeSEO = async () => {
  const currentUrl = window.location.pathname;
  const pageData = metaData[currentUrl];

  if (pageData) {
    document.title = pageData.title;
    const descriptionMeta = document.querySelector('meta[name="description"]');
    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    const ogDescriptionMeta = document.querySelector(
      'meta[property="og:description"]',
    );
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    await Promise.all([
      updateAttribute(descriptionMeta, 'content', pageData.description),
      updateAttribute(keywordsMeta, 'content', pageData.keywords),
      updateAttribute(ogTitleMeta, 'content', pageData.title),
      updateAttribute(ogDescriptionMeta, 'content', pageData.description),
      updateAttribute(canonicalLink, 'href', window.location.href),
    ]);
  }
};

const updateAttribute = (element, attributeName, value) => {
  return new Promise(resolve => {
    if (element) {
      element.setAttribute(attributeName, value);
      resolve();
    }
  });
};




export const generarParamsOportunidad = (
  oportunidadId,
  cotizacionId,
  mensajeBitacora,
  tipo,
  codigoEtapaNueva,
  codigoEtapaAnterior,
  values,
) => {
  return {
    oportunidadId,
    cotizacionId,
    mensajeBitacora,
    tipo,
    etapa: codigoEtapaNueva,
    etapaAnterior: {
      codigo: codigoEtapaAnterior,
      values,
    },
  };
};

export const functionSearchParams = paso => {
  switch (paso) {
    case 1:
      return 'seguro';
    case 2:
      return 'personal';
    case 3:
      return 'resultado';
    case 4:
      return 'emision';
    case 5:
      return 'pago';
    case 6:
      return 'confirmacion';
    default:
      return '';
  }
};
export const functionSearchParamsPasoToNumber = paso => {
  switch (paso) {
    case 'COT':
      return 3;
    case 'EMI':
    case 'DOC':
      return 4;
    case 'PAG':
      return 5;
    case 'GAN':
      return 6;
    default:
      return 3;
  }
};

export const addQueryParam = (key, paso) => {
  const value = functionSearchParams(paso);
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.replaceState(null, '', url);
};

export const changeQueryParam = (key, paso) => {
  const newValue = functionSearchParams(paso);
  const url = new URL(window.location);
  url.searchParams.set(key, newValue);
  window.history.replaceState(null, '', url);
};

export const capitalizarPalabra = palabra => {
  if (palabra !== undefined)
    return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
  else return '';
};

export const capitalizarMultiplesPalabras = palabra => {
  if (palabra !== undefined) {
    if(palabra.split(' ').length > 1) {
      return palabra.split(' ').map( w => ( w.charAt(0).toUpperCase() + w.slice(1).toLowerCase() ) ).join(' ')
    }
    return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
  } else return '';
};

export const encryptCorreo = correo => {
  const partes = correo.split('@');
  const primerCaracter = partes[0].slice(0, 2);
  const ultimoCaracter = partes[0].substring(partes[0].length - 2);
  const nuevoCorreo =
    primerCaracter + '****' + ultimoCaracter + '@' + partes[1];

  return nuevoCorreo;
};

export const encryptTelefono = telefono => {
  return telefono?.split('-')[1] ?? telefono;
};

export const calcularDiasEntreFechas = (
  fechaInicio,
  fechaFin,
  agregarDiasDiferencia = 0,
) => {
  //fecha en formato DD/MM/YYYY
  const fecha1 = dayjs(fechaInicio, 'DD/MM/YYYY');
  const fecha2 = dayjs(fechaFin, 'DD/MM/YYYY');
  const dias = fecha2.diff(fecha1, 'day');
  return dias + agregarDiasDiferencia;
};

export const convertirColones = texto => {
  let valorEnColones = texto;

  valorEnColones = valorEnColones.replace('₡', '');
  valorEnColones = valorEnColones.replace(',', '.');
  valorEnColones = valorEnColones.replace(/\s/g, ',');

  return valorEnColones;
};


export const getBienes = (dataCotizar, data) => {
  let riesgoAsegurado = {
    Bienes: {},
    idCotizacion: '',
  };

  switch (dataCotizar.seguro.codigo) {
    case 'SEG-DA':
      riesgoAsegurado.Bienes = {
        vehiculo: { id_vehiculo: data.id_vehiculo, tipo: 'vehiculo' },
      };

      riesgoAsegurado.idCotizacion = data.idCotizacion;

      break;

    default:
      break;
  }

  return riesgoAsegurado;
};


export const filtrarPreguntasVehiculo = (preguntas = []) =>{
  return preguntas.filter((item => {
    return item.value === "Año" || item.value === "Marca"|| item.value === "modeloVehiculo" || item.value === "propietario"
    
  }))
}