// Components
import { ButtonCustom, LoaderCotizar, TextCustom } from '../../atoms';

// Assets
import Logo from '../../../assets/images/Logos/INSLogo.svg';

// Hooks
import { useCotizador } from '../../../hooks/others';
import { Link } from 'react-router-dom';

const CardResultado = ({
  all = [],
  idPlan = '',
  data = '',
  index = '',
  error = false,
  pasoSiguiente = () => null,
}) => {
  const { dataCotizar, formaPagoSelect } = useCotizador();

  const existeData = () => {
    const newData = all.find(dat => dat._id === idPlan)?.data;
    if (newData) {
      data = newData;
      return true;
    } else {
      return false;
    }
  };

  const selectFormaPago = (data, formaPagoSelect) => {

    if (data && data.primas) {
      const objectPrima = data.primas.find(
        item => item.frecuenciaPago === formaPagoSelect,
      );
      return objectPrima
        ? {
            frecuenciaPago: objectPrima.frecuenciaPago,
            primaTotal: objectPrima.primaTotal,
            montoPrima: objectPrima.montoPrima,
          }
        : null;
    }
    return null;
  };

  return (
    <div
      className={`${
        error ? 'bg-card-error' : 'bg-card'
      } h-full rounded-md px-4 pt-0 relative `}
      style={{ width: 248, display: 'inline-block' }}
    >
      {error ? (
        <div className="flex text-center items-center justify-between w-full h-full fontPSemiBold color-primary text-[25px]">
          Resultado no disponible
        </div>
      ) : existeData() === false ? (
        <LoaderCotizar type="full" className={`texto-plan-slider-${index}`} />
      ) : (
        <>
          <div className="bg-primary-100 rounded-t-md absolute w-full left-0 py-1 px-2">
            <span className=" text-primary fontPSemiBold text-sm">
              Plan {data.orden ? data.orden : index + 1}
            </span>
          </div>

          {dataCotizar.seguro.formasPago  ? (
            <>
              <div className="mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10">
                <img
                  src={Logo}
                  alt="Imagen de aseguradora"
                  width={150}
                  className="mx-auto"
                />
                <TextCustom
                  text={data.codigoComercial}
                  component="p"
                  className="fontPRegular color-primary text-xs -mt-[5px]"
                />

                <div className="pb-[50px]">
                  <TextCustom
                    text={
                      selectFormaPago(data, formaPagoSelect).primaTotal
                    }
                    component="p"
                    className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                  />
                  <TextCustom
                    text="Prima total"
                    component="p"
                    className="pt-0 fontPRegular text-base"
                  />
                  <TextCustom
                    text="(IVA Incluido)"
                    component="p"
                    className="pt-0 fontPRegular text-xs color-outline"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={dataCotizar.seguro.codigo === "SEG-V" ? " mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10 pb-[40px]" : "mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10 "  }>
              <img
                src={Logo}
                alt="Imagen de aseguradora"
                width={150}
                className="mx-auto"
              />
              <TextCustom
                text={data.codigoComercial}
                component="p"
                className="fontPRegular color-primary text-xs -mt-[5px]"
              />
          
          <div className="pb-[50px]">
                  <TextCustom
                    text={
                      selectFormaPago(data, formaPagoSelect).primaTotal ?? ''
                    }
                    component="p"
                    className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                  />
                  <TextCustom
                    text="Prima total"
                    component="p"
                    className="pt-0 fontPRegular text-base"
                  />
                  <TextCustom
                    text="(IVA Incluido)"
                    component="p"
                    className="pt-0 fontPRegular text-xs color-gray-secondary"
                  />
                </div>
            </div>
          )}
          {
          dataCotizar.seguro.formasPago  &&
          formaPagoSelect !== 'Anual' ? (
            <div className="border-b pb-5 text-center mt-4 h-12">
              <TextCustom
                text={`Cuota ${formaPagoSelect}`}
                className="color-ligth-gray fontPRegular text-sm"
                component="p"
                variant="p"
              />

              <div>
                <TextCustom
                  text={
                    selectFormaPago(data, formaPagoSelect)?.montoPrima || ''
                  }
                  className="fontPSemiBold text-sm"
                  component="p"
                  variant="p"
                />
              </div>
            </div>
          ) : null}

          <div className="mt-1 text-center">
            {data?.coberturas.map((resultado, index) => {
              // Verificar si resultado?.descripcion es cero o está vacío
              const esCero = /^\$0\.00|₡0\.00|\$0,00|₡0,00$/.test(
                resultado?.descripcion,
              );

              // Si es cero y el código es '78O' o '77P', mostrar 'Amparado'
              if (
                esCero &&
                (resultado?.codigo === '78O' || resultado?.codigo === '77P')
              ) {
                return (
                  <TextCustom
                    text="Amparado"
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si no es cero, mostrar la descripción original
              if (!esCero) {
                return (
                  <TextCustom
                    text={resultado?.descripcion}
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si el resultado es cero y el código no es '78O' ni '77P', no renderizar nada
              return null;
            })}
          </div>

          <div className="text-center py-10">
            <ButtonCustom
              text={`${data?.pasos ? 'Comprar' : 'Solicitar'}`}
              width="160px"
              className="fontPSemiBold"
              onClick={()=>pasoSiguiente(data, idPlan)}
            />
          </div>
          <Link
            to={data?.linkCondiciones}
            className="fontPRegular color-primary text-sm flex justify-center underline cursor-pointer"
            target="_blank"
          >
            Condiciones Generales
          </Link>
        </>
      )}
    </div>
  );
};

export default CardResultado;
