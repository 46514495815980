export function labelFormasPago(formaPagoSelect, primas) {
  const { montoPrima: cuota, primaTotal } =
    primas.find(item => item?.frecuenciaPago === formaPagoSelect) || {};
  switch (formaPagoSelect) {
    case 'Mensual':
      return { numeroCuotas: '12', cuota: cuota, primaTotal: primaTotal };

    case 'Trimestral':
      return { numeroCuotas: '4', cuota: cuota, primaTotal: primaTotal };

    case 'Semestral':
      return { numeroCuotas: '2', cuota: cuota, primaTotal: primaTotal };

    case 'Anual':
      return { cuota: cuota, primaTotal: primaTotal };

    default:
      return '';
  }
}



export function convertFormaPago(formaPago){
  switch (formaPago){
    case "M":
      return "Mensual"
    case "S":
      return "Semestral"
    case "T":
      return "Trimestral"
    case "A":
      return "Anual"
    default:
      return "Anual"
      
  }
}
