import React, { memo } from 'react';

// Components
import { Dialog } from '@mui/material';
import { DialogTitleCustom } from '../../molecules';

const DialogCustom = ({
  children = null,
  disabledDismiss = false,
  disabledIconClose = false,
  onDismiss = () => null,
  open = false,
  setOpen = () => null,
  title = '',
  border = '',
  severity = '',
  className = '',
}) => {
  const handleClose = () => {
    onDismiss();
    setOpen(false);
    return false;
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => !disabledDismiss && handleClose()}
      maxWidth={false}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 3,
          padding: '15px',
          borderBottom: border && `15px solid ${border}`,
        },
      }}
      className={className}
    >
      <DialogTitleCustom
        onClose={handleClose}
        disabledIconClose={disabledIconClose}
        severity={severity}
      ></DialogTitleCustom>
      {title && (
        <p className="fontPSemiBold py-2 text-xl md:text-3xl text-center color-primary mt-10">
          {title.includes(':') ? (
            <span>
              {title.split(' ')[0]} <br className="xxs:hidden" />
              {title
                .split(' ')
                .filter((texto, index) => index !== 0 && texto)
                .join(' ')}
            </span>
          ) : (
            title
          )}
        </p>
      )}
      {children}
    </Dialog>
  );
};

export default memo(DialogCustom);
