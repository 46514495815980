import { useEffect, useRef } from 'react';

//Componentes
import { TextCustom } from '../../atoms';

// Utils
import { saltoLinea } from '../../../core/utils';


const TitulosCotizador = ({ codigo = '', descripcion = '', color = '' }) => {
  const subtituloRef = useRef(null);

  const renderTitulo = {
    'SEG-DA': {
      renderTitulo: (
        <h2
          className={`fontPBold color-primary mb-2 leading-10 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Deducible de <br /> Automóvil (¢)
        </h2>
      ),
    },
    'SEG-V': {
      renderTitulo: (
        <h2
          className={`fontPBold color-primary mb-2 leading-10 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Viaje ($)
        </h2>
      ),
    },
    'SEG-AEG': {
      renderTitulo: (
        <h2
          className={`fontPBold color-primary mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Enfermedades <br /> Graves (¢)
        </h2>
      ),
    },
    'SEG-ONC': {
      renderTitulo: (
        <h2
          className={`fontPBold color-primary mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro <br /> Oncológico Plus (¢)
        </h2>
      ),
    },
    'SEG-VID': {
      renderTitulo: (
        <h2
          className={`fontPBold color-primary mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de
          <br /> Vida Plus (¢)
        </h2>
      ),
    },
  };

  useEffect(() => {
    subtituloRef.current.innerHTML = saltoLinea(descripcion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linkCondiciones = codigo => {
    let link = '';

    switch (codigo) {
      case 'SEG-V':
        link =
          'https://aseguramecr.s3.us-east-2.amazonaws.com/Documentos/Condiciones/CG-+Viajero+con+Asistencia+Autoexpedible+-+P19-57-A01-972-v3.pdf';
        break;
      case 'SEG-DA':
        link =
          'https://aseguramecr.s3.us-east-2.amazonaws.com/Documentos/Condiciones/CG-+Deducible+Automoviles+INS+Autoexpedible+-+G11-22-A01-1031-v3.pdf';
        break;

      case 'SEG-ONC':
        link =
          'https://aseguramecr.s3.us-east-2.amazonaws.com/Documentos/Condiciones/CG-Oncologico-Plus-Autoexpedible-P20-62-A01-918-v3.pdf';
        break;

      case 'SEG-AEG':
        link =
          'https://aseguramecr.s3.us-east-2.amazonaws.com/Documentos/Condiciones/CG-+Enfermedades+Graves+Plus+Autoexpedible+-+P20-62-A01-923-v2.pdf';
        break;

      default:
        link = `${window.location.origin}`;
        break;
    }

    return link;
  };

  const linkPreguntasFrecuentes = codigo => {
    const link = `/preguntas-frecuentes#${codigo}`;
    return link;
  };
  return (
    <div>
      <TextCustom
        text="Compra en línea"
        variant="p"
        component="p"
        className="color-secondary fontPSemiBold"
      />
      {renderTitulo[codigo]?.renderTitulo}
      <p
        ref={subtituloRef}
        className={`text-descripcion-seg fontPRegular color-gray ${color}`}
      >
        {descripcion}
      </p>
      <div className="flex  w-40  gap-5 pt-3">
        <div className="flex gap-[6.67px] ">
          <img
            src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/IconCondiciones.svg"
            alt=""
          />
          <a
            href={linkCondiciones(codigo)}
            target="_blank"
            rel="noopener noreferrer"
            className="fontPRegular w-[143px] whitespace-nowrap p-[2px] text-xs"
          >
            Condiciones generales
          </a>
        </div>
        <div className="flex gap-[6.67px]">
          <img
            src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/IconInterrogacion.svg"
            alt=""
          />
          <a
            href={linkPreguntasFrecuentes(codigo)}
            target="_blank"
            rel="noopener noreferrer"
            className="fontPRegular w-[143px] whitespace-nowrap p-[2px] text-xs"
          >
            Preguntas Frecuentes
          </a>
        </div>
      </div>
    </div>
  );
};

export default TitulosCotizador;
