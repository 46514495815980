import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';

const Error500 = () => {
  const navigate = useNavigate();

  //Assets
  const Error500Image =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Errors/Error500+.webp';

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="contenedor-page-error flex justify-center items-center text-center h-screen w-screen flex-col bg-white fixed top-0 gap-5 px-5">
      <img src={Error500Image} alt="Error500Image" className="img-500" />
      <TextCustom
        text="ERROR 500"
        className="fontPMedium color-secondary text-xl pt-1"
      />
      <TextCustom
        text="Parece que hemos encontrado un error"
        variant="h2"
        className="fontPBold color-primary"
      />
      <TextCustom
        text="Perdona las molestias, ya estamos trabajando para solucionarlo."
        className="fontPRegular"
      />
      <ButtonCustom
        text="Regresar"
        height="auto"
        width="200px"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default Error500;
