import axios from 'axios';
import buildRequest from '../buildRequest';
import { typesEndpoint } from '../../../common/types';

export const apiCrearDataCRM = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.postDataCotizador}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const request = {
    codigo: params.codigoCategoria,
    datosPersonales: params.dataPersonal,
    dataSeguro: params.dataSeguro,
    codigoAliado: params.codigoAliado,
    dataOportunidad: params.dataOportunidad,
    tokenReCaptcha: params.tokenReCaptcha,
    tipo: params.tipo
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiVerificarDataCRM = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.postVerificarDataCotizador}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    cotizacionEliminada: false,
  };
  const request = {
    cotizacionId: params,
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.cotizacionEliminada = data.cotizacionEliminada;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiActualizarOportunidad = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.postActualizarOportunidad}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };


  const request = {
    oportunidadId: params.oportunidadId,
    cotizacionId: params.cotizacionId,
    mensajeBitacora: params.mensajeBitacora,
    tipo: params.tipo,
    etapa: params.etapa,
    etapaAnterior: params.etapaAnterior,
    planId: params.planId,
    formaPagoSelect: params.formaPagoSelect
    
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
