import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

// Componets
import { TextCustom } from '../../atoms';

// Hooks
import { useApp } from '../../../hooks/others';

// Styles
import './style.css';

// Constantes
import { rutasCotizar } from '../../../common/constants';
import { renderImagen, renderNombre } from '../../../core/utils';
import {CarouselCustom} from "../../organisms";
import {SwiperSlide} from "swiper/react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TipoCotizacion = ({ isWhite = false }) => {
  const { seguros } = useApp();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
const segurosCant = Array.isArray(seguros) ? (matches ? (seguros.length > 3 ? seguros.length === 4 ? 3 : Math.round(seguros.length / 2) : Number(seguros.length)) : 3) : 1
  const navigate = useNavigate();

  // Assets
  const VidaIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-SeguroVida.webp';
  const Oncologico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-Oncologico.webp';
  const Proteccion =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-ProteccionMultiple.webp';

  const handleNavigate = tipo => {
    const ruta = rutasCotizar.find(ruta => ruta.codigo === tipo.codigo)?.ruta;
    navigate(ruta, { state: tipo });
  };


  return (
    <>
      <div className="relative hidden xl:flex gap-4 justify-center max-w-[100vw] mx-auto text-center">
        {seguros.map(tipo => (
          <div
            onClick={() => handleNavigate(tipo)}
            key={tipo.codigo}
            className="flex flex-col items-center"
          >
            <div className="contenedor-iconos bg-contrast-content">{renderImagen[tipo.codigo]}</div>
            <>
              <TextCustom
                text={
                  tipo.nombre.includes('Seguro de ') ? 'Seguro de' : 'Seguro'
                }
                className="text-[10px] mt-2 fontPRegular text-contrast-content"
                component="p"
                variant="p"
              />
              <TextCustom
                text={renderNombre(tipo.nombre)}
                variant="p"
                component="p"
                className={` ${
                  isWhite ? 'text-contrast-content' : 'color-primary'
                }  font-bold md:text-sm text-xs fontPRegular text-center text-seguro`}
              />
            </>
          </div>
        ))}
        <div className="flex flex-col items-center md:flex-col cursor-pointer justify-items-center">
          <div className="contenedor-iconos bg-contrast-content">
            <img
              id="vida"
              src={VidaIcon}
              alt="asegurameVida"
              className="icono-cotizar"
            />
          </div>
          <TextCustom
            text="Seguro de"
            className="text-[10px] mt-2 fontPRegular text-contrast-content"
            component="p"
            variant="p"
          />
          <TextCustom
            text="Vida"
            className={` ${
              isWhite ? 'text-contrast-content' : 'color-primary'
            }  font-bold md:text-sm text-xs fontPSemiBold text-center text-seguro`}
          />
          <TextCustom
            text="Próximamente"
            className="text-[8px] fontPRegular text-contrast-content"
            component="p"
            variant="p"
          />
        </div>
        
        <div className="grid justify-center md:flex-col cursor-pointer justify-items-center">
          <div className="contenedor-iconos bg-contrast-content">
            <img
              id="proteccion"
              src={Proteccion}
              alt="asegurameProteccion"
              className="icono-cotizar"
            />
          </div>
          <TextCustom
            text="Seguro de"
            className="text-[10px] mt-2 fontPRegular text-contrast-content"
            component="p"
            variant="p"
          />
         
          <TextCustom
            text="Próximamente"
            className="text-[8px] fontPRegular text-contrast-content"
            component="p"
            variant="p"
          />
        </div>
      </div>
      <div
        className={`max-w-[100vw] mx-auto text-center mb-1 lg:mb-12 mt-2 xl:hidden`}
      >
        <CarouselCustom spaceBetween={0} slidesPerView={segurosCant} autoplay={true}>
          {seguros.map((seguro, idx) => (
            <SwiperSlide key={idx} className={'grid'}>
              <div className="flex flex-col items-center" key={seguro.codigo}>
                <div
                  className="bg-card card-seguros-home flex justify-center items-center px-0 py-0 cursor-pointer hover:scale-105 transition-all duration-300 w-[75px] h-[75px] lg:w-[85px] lg:h-[85px] xl:grid xl:grid-cols-2 xl:w-[300px] xl:h-[125px] xl:px-9 xl:py-5 bg-contrast-content"
                  onClick={() => handleNavigate(seguro)}
                >
                  <div className="flex justify-center items-center xl:justify-end xl:items-center">
                    {renderImagen[seguro.codigo]}
                  </div>
                </div>
                <TextCustom
                  text="Seguro de"
                  className="text-[10px] mt-2 fontPRegular text-contrast-content"
                  component="p"
                  variant="p"
                />
                <TextCustom
                  text={renderNombre(seguro.nombre)}
                  className="fontPSemiBold text-contrast-content text-xs text-center w-[95px] lg:w-[110px] lg:text-base"
                  component="p"
                  variant="p"
                />
              </div>
            </SwiperSlide>
          ))}
        </CarouselCustom>
      </div>
    </>
  );
};

export default TipoCotizacion;
