
export function getPaddinButton(seguro){
    switch (seguro){
        case "SEG-V":
            return "25px"

        case "SEG-AEG":
            return "66px"
       default:
        return "60px"
    }
}