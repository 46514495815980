import React, { memo } from 'react';

// Components
import { InputAdornment, TextField } from '@mui/material';
import { IconButtonCustom, MensajeError } from '../';

//assets
import { ReactComponent as IconoError } from '../../../assets/icons/Icono-Error.svg';
import { ReactComponent as IconoListo } from '../../../assets/icons/Icono-Listo.svg';

// Styles
import { colors } from '../../styles/theme';

const { general, black, white, ligthGray, error, green, bordes } = colors;

const TextInputCustom = ({
  name = '',
  value = '',
  onBlur = () => null,
  onEnter = () => null,
  onChange = () => null,
  placeholder = '',
  type = 'text',
  maxLength = null,
  className = '',
  iconStart = null,
  iconEnd = null,
  iconMode = 'adornment',
  iconTypeColor = 'primary',
  iconOnClick = () => null,
  msgError = '',
  disabled = false,
  multiline = false,
  required = false,
  success = false,
  warning = false,
  fontSize = 16,
  errorInput = false,
  borderStyle = false,
  alignError = '',
}) => {
  const renderIcon = icon => {
    if (icon) {
      return iconMode === 'button' ? (
        <IconButtonCustom
          icon={icon}
          onClick={iconOnClick}
          typeColor={iconTypeColor}
        />
      ) : (
        <InputAdornment position="start">{icon}</InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <TextField
        label={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={event => {
          if (event.code === 'Enter') {
            onEnter();
          }
        }}
        variant="outlined"
        size="large"
        multiline={multiline}
        minRows={multiline ? '3' : '1'}
        maxRows={multiline ? '4' : '1'}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        required={required}
        inputProps={{
          maxLength: maxLength,
          className: 'text-base',
          style: {
            textAlign: 'left',
            padding: '16px 14px',
            fontFamily: 'Poppins-SemiBold',
            borderRadius: 20,
            color:
              msgError.length > 0 || errorInput
                ? error
                : success
                ? green
                : general,
            fontWeight: 'bold',
          },
        }}
        InputProps={{
          startAdornment:
            type === 'tel' ? (
              <span className="border-r text-base border-outline py-[16px] pl-[1px] pr-[14px] fontPSemiBold text-primary">
                +506
              </span>
            ) : (
              renderIcon(iconStart)
            ),
          endAdornment:
            msgError.length > 0 || errorInput
              ? renderIcon(<IconoError className="mt-1" />)
              : success && <IconoListo />,
        }}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'var(--color-primary)',
            backgroundColor: 'var(--color-contrast-content)',
            borderRadius: 5,

            // '& fieldset': {
            //   border: borderStyle,
            //   borderColor: error,
            // },
          },
          '& .MuiInputBase-root.Mui-disabled': {
            '& fieldset': {
              borderRadius: 5,
              border: 1,

              borderColor: bordes,
            },
          },

          /* aqui se quita el borde al hacer hover */
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
            },
          },

          '& legend': {
            marginLeft: 2,
            fontSize: fontSize * 0.82,
          },
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderRadius: 5,
              border:
                msgError.length > 0 || success || errorInput
                  ? 2
                  : borderStyle
                  ? 1
                  : 0,
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
              color: general,
              boxShadow: borderStyle && 'var(--sombra-secondary)',
            },
            /* aqui se quita/agrega borde mientras haga foco  */

            '&.Mui-focused fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? general
                  : 'transparent',
              color: black,
              fontSize: fontSize,
            },
          },
          '& .MuiInputLabel-asterisk': {
            color: error,
          },
          '& .MuiInputLabel-shrink': {
            marginLeft: 2,
            color: black,
            fontSize: fontSize,
            fontWeight: '600',
            '& .MuiInputLabel-asterisk': {
              color: error,
              display: 'inline',
            },
          },

          backgroundColor: disabled ? ligthGray : white,
          borderRadius: 5,
          marginTop: 1,
        }}
      />
      {msgError && <MensajeError mensaje={msgError} alignError={alignError} />}
    </div>
  );
};

export default memo(TextInputCustom);
