import { TextCustom } from '../../atoms';
import { TipoCotizacion } from '../../molecules';

//style
import './style.css';

const SeccionComparaSeguros = () => {
  return (
    <section
      className="lg:p-16 md:px-7 mx-auto mt-5 grid-compara-seguros bg-primary bg-opacity-90"
    >
      <div className="text-center titulo-seccion">
        <TextCustom
          text="¡Compara y cotiza tu seguro!"
          variant="h1"
          component="h2"
          className="text-contrast-content font-bold fontPSemiBold text-[20px] md:text-[25px] lg:text-[30px]"
        />
        <TextCustom
          text="
      Vive la experiencia más fácil de cotizar y comparar seguros."
          variant="p"
          component="p"
          className="text-contrast-content fontPRegular mt-2 text-[12px] md:text-[14px] lg:text-[16px]"
        />
      </div>
      <div className="mt-10">
        <TipoCotizacion isWhite={true} />
      </div>
    </section>
  );
};

export default SeccionComparaSeguros;
