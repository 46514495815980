// Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { TextCustom } from '../../atoms';

const RenderCoberturas = ({ cobertura }) => {
  return (
    <>
      <Accordion
        className="bg-card-fq rounded-[20px] mb-5 lg:hidden"
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        key={cobertura.nombre}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          //onClick={e => handleChange(e, cobertura.nombre)}
        >
          <div className="grid grid-cols-5 gap-4 items-center">
            <img
              src={cobertura.icono}
              className="icono-coberturas col-span-1"
              alt={cobertura.nombre}
            />
            <Typography className="fontPSemiBold color-general text-base flex justify-between w-full col-span-4">
              {cobertura.nombre}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          className="md:w-11/12"
          style={{ padding: '16px 16px 16px' }}
        >
          <Typography className="fontPRegular text-sm md:text-base">
            {cobertura?.descripcionInformativa}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className="bg-card hidden lg:flex p-6 items-center lg:flex-col lg:items-start gap-4 w-[95%] md:w-[300px] lg:w-[365px]">
        <div>
          <div className="flex gap-4 items-center lg:items-start lg:gap-0 lg:flex-col lg:h-[90px]">
            <img
              src={cobertura.icono}
              className="icono-coberturas"
              alt={cobertura.nombre}
            />
            <TextCustom
              text={cobertura.nombre}
              className="fontPSemiBold mt-3 mb-2 md:text-sm lg:text-base text-primary"
              component="p"
            />
          </div>
          <div className="lg:h-[100px]">
            <TextCustom
              text={cobertura?.descripcionInformativa}
              className="hidden text-sm fontPRegular text-secondary-content leading-6 lg:block "
              component="p"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderCoberturas;
