import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { ButtonCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';
import { TextCustom } from '../../atoms';
import { ReactComponent as LogoWhatsapp } from '../../../assets/icons/Footer/whatsapp-logo.svg';


const ModalCotizacion = ({
    open = false,
    setOpen = () => null,
    data 
  }) =>
  {
    const mensaje = `Hola, estoy teniendo problemas para obtener la cotización de los planes. Estos son mis datos:\n\n *Identidad:* ${data.numeroIdentificacion}\n *Nombre:* ${data.primerNombre} ${data.primerApellido}\n\n Me podrían ayudar a continuar el proceso, por favor`;

    return (
      <DialogCustom open={open} setOpen={setOpen} >
        <DialogContent className="fontPRegular">
          <div className="mt-16 max-w-[639px] lg:w-[639px] ">
            <TextCustom
              text="¡Hemos presentado un inconveniente!"
              className="text-primary fontPSemiBold text-2xl flex justify-center items-center pb-6"
              component="p"
              variant="p"
            />
            <p className="text-center">
              Estamos presentando problemas para obtener la cotización de
              <span className="hidden md:inline">
                <br />
              </span>
              los planes por parte de la aseguradora, puedes contactarnos
              <span className="hidden md:inline">
                <br />
              </span>
              para seguir con el proceso.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-center w-full pb-3">
            <Link
            to={`https://wa.me/50686473773?text=${encodeURIComponent(mensaje)}`}
            target="_blank"
          >
              <ButtonCustom
                text="Contáctanos"
                startIcon={<LogoWhatsapp className="size-5 text-secondary" />}
                variant="outlined"
                className="lg:w-[202px] lg:h-[38px] xxxxs:w-[180px] xxxxs:h-[38px]"
              />
            </Link>
          </div>
        </DialogActions>
      </DialogCustom>
    );
  };

export default ModalCotizacion;

