// Components
import { ButtonCustom, LoaderCotizar, TextCustom } from '../../atoms';

// Assets
import Logo from '../../../assets/images/Logos/INSLogo.svg';
import { ReactComponent as RecomendadoIcon } from '../../../assets/icons/Icono-Estrella.svg';
// Hooks
import { useCotizador } from '../../../hooks/others';
import { Link } from 'react-router-dom';

const CardSeleccionada = ({
  data = '',
  idPlan = '',
  pasoSiguiente = () => null,
}) => {
  const { dataCotizar, formaPagoSelect } = useCotizador();

  const selectFormaPago = (data, formaPagoSelect) => {
    if (data && data.primas) {
      const objectPrima = data.primas.find(
        item => item.frecuenciaPago === formaPagoSelect,
      );
      return objectPrima
        ? {
            frecuenciaPago: objectPrima.frecuenciaPago,
            primaTotal: objectPrima.primaTotal,
            montoPrima: objectPrima.montoPrima,
          }
        : null;
    }

    return null;
  };

  return (
    <div className="bg-card rounded-md px-4 pt-0 relative tarjeta-estatica h-full">
      {!data ? (
        <LoaderCotizar type="full" />
      ) : (
        <>
          <div className="bg-primary flex justify-between items-center rounded-t-md absolute w-full left-0 py-[7px] px-2">
            <span className=" text-contrast-content fontPRegular text-sm">
              Plan {data.orden} (Recomendado)
            </span>
            <RecomendadoIcon />
          </div>

          {dataCotizar.seguro.formasPago ? (
            <>
              <div className="mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10">
                <img
                  src={Logo}
                  alt="Imagen de aseguradora"
                  width={150}
                  className="mx-auto"
                />
                <TextCustom
                  text={data.codigoComercial}
                  component="p"
                  className="fontPRegular color-primary text-xs -mt-[5px]"
                />

                <div className="pb-[50px]">
                  <TextCustom
                    text={
                      selectFormaPago(data, formaPagoSelect).primaTotal ?? ''
                    }
                    component="p"
                    className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                  />
                  <TextCustom
                    text="Prima total"
                    component="p"
                    className="pt-0 fontPRegular text-base"
                  />
                  <TextCustom
                    text="(IVA Incluido)"
                    component="p"
                    className="pt-0 fontPRegular text-xs color-gray-secondary"
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              className={
                dataCotizar.seguro.codigo === 'SEG-V'
                  ? 'mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10 pb-[38px]'
                  : '  mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10 '
              }
            >
              <img
                src={Logo}
                alt="Imagen de aseguradora"
                width={150}
                className="mx-auto"
              />
              <TextCustom
                text={data.codigoComercial}
                component="p"
                className="fontPRegular color-primary text-xs -mt-[5px]"
              />

                <div className="pb-[50px]">
                  <TextCustom
                    text={
                      selectFormaPago(data, formaPagoSelect).primaTotal ?? ''
                    }
                    component="p"
                    className="pt-5 px-2 fontPSemiBold color-primary text-2xl"
                  />
                  <TextCustom
                    text="Prima total"
                    component="p"
                    className="pt-0 fontPRegular text-base"
                  />
                  <TextCustom
                    text="(IVA Incluido)"
                    component="p"
                    className="pt-0 fontPRegular text-xs color-gray-secondary"
                  />
                </div>
            </div>
          )}

          {dataCotizar.seguro.formasPago && formaPagoSelect !== 'Anual' ? (
            <div className="border-b pb-5 text-center mt-4 h-12">
              <TextCustom
                text={`Cuota ${formaPagoSelect}`}
                className="color-lightgray fontPRegular text-sm"
                component="p"
                variant="p"
              />

              <div>
                <TextCustom
                  text={
                    selectFormaPago(data, formaPagoSelect)?.montoPrima || ''
                  }
                  className="fontPSemiBold text-sm"
                  component="p"
                  variant="p"
                />
              </div>
            </div>
          ) : null}

          <div className="mt-1 text-center">
            {data?.coberturas.map((resultado, index) => {
              // Verificar si resultado?.descripcion es cero o está vacío
              const esCero = /^\$0\.00|₡0\.00|\$0,00|₡0,00$/.test(
                resultado?.descripcion,
              );

              // Si es cero y el código es '78O' o '77P', mostrar 'Amparado'
              if (
                esCero &&
                (resultado?.codigo === '78O' || resultado?.codigo === '77P')
              ) {
                return (
                  <TextCustom
                    text="Amparado"
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si no es cero, mostrar la descripción original
              if (!esCero) {
                return (
                  <TextCustom
                    text={resultado?.descripcion}
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si el resultado es cero y el código no es '78O' ni '77P', no renderizar nada
              return null;
            })}
          </div>

          <div className="text-center py-10">
            <ButtonCustom
              text={`${data?.pasos ? 'Comprar' : 'Solicitar'}`}
              width="160px"
              className="fontPSemiBold"
              onClick={() => pasoSiguiente(data, idPlan)}
            />
          </div>
          <Link
            to={data?.linkCondiciones}
            className="fontPRegular color-primary text-sm flex justify-center underline cursor-pointer"
            target="_blank"
          >
            Condiciones Generales
          </Link>
        </>
      )}
    </div>
  );
};

export default CardSeleccionada;
