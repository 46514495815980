import React, { useEffect, useState } from 'react';

// Components
import Accordion from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  SelectCustom,
  SelectMultiCustom,
  TextCustom,
  TextInputCustom,
} from '../../atoms';
import ModalAlertas from '../ModalAlertas';

// Hooks
import { useCotizador, useApp } from '../../../hooks/others';

// Const
import { maxBeneficiarios, parentescoArray } from '../../../common/constants';
import { typesValidation } from '../../../common/types';

// API
import { apiGetPersona } from '../../../services/apis';

const CollapseForm = ({ action = () => null }) => {
  const { identidades } = useApp();
  const { setBeneficiarios, beneficiarios, recuperarBeneficiarios } =
    useCotizador();

  const [arrayBeneficiarios, setArrayBeneficiarios] = useState([]);
  const [porcentaje, setPorcentaje] = useState(0);
  const [modal, setModal] = useState(false);
  const [loaderInput, setLoaderInput] = useState(false);

  useEffect(() => {
    let newArray = [];
    if (beneficiarios.length > 0) {
      newArray = beneficiarios;
    } else {
      for (let numB = 0; numB < maxBeneficiarios; numB++) {
        newArray.push({
          tipoIdentificacion: '0',
          numeroIdentificacion: '',
          primerNombre: '',
          segundoNombre: '',
          primerApellido: '',
          segundoApellido: '',
          parentesco: '',
          porcentaje: '',
          mostrar: numB === 0 ? true : false,
          titulo:
            numB === 0
              ? '+ Agregar Beneficiario'
              : '+ Agregar Otro Beneficiario',
          add: false,
          estado: false,
          tipoIdentificacionError: false,
          numeroIdentificacionError: false,
          primerNombreError: false,
          segundoNombreError: false,
          primerApellidoError: false,
          segundoApellidoError: false,
          parentescoError: false,
          porcentajeError: false,
          fechaNacimiento: '',
          fechaNacimientoError: false,
        });
      }
    }

    setArrayBeneficiarios(newArray);
    // setBeneficiarios(arrayBeneficiarios);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBeneficiarios(arrayBeneficiarios);
    if (arrayBeneficiarios.length && recuperarBeneficiarios) handleBlur(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayBeneficiarios]);

  const handleBeneficiario = index => {
    if (porcentaje !== 100) {
      setArrayBeneficiarios(prevBeneficiarios => {
        const updatedBeneficiarios = [...prevBeneficiarios];

        updatedBeneficiarios[index] = {
          ...updatedBeneficiarios[index],
          add: true,
          estado: true,
        };

        if (index + 1 < updatedBeneficiarios.length) {
          updatedBeneficiarios[index + 1] = {
            ...updatedBeneficiarios[index + 1],
            mostrar: true,
          };
        }
        action(updatedBeneficiarios, 'beneficiarios');
        return updatedBeneficiarios;
      });
    } else {
      setModal(true);
    }
  };

  const handleChange = index => {
    if (porcentaje !== 100) {
      setArrayBeneficiarios(prevBeneficiarios => {
        const updatedBeneficiarios = [...prevBeneficiarios];

        updatedBeneficiarios[index] = {
          ...updatedBeneficiarios[index],
          estado: !updatedBeneficiarios[index].estado,
        };

        return updatedBeneficiarios;
      });
    }
  };

  const handleSetValue = async (value, index, name) => {
    const tipoID = arrayBeneficiarios[index]?.tipoIdentificacion;

    const renderValueIdentidad = identificacion => {
      switch (identificacion) {
        case 'Nacional':
          return '0';
        default:
          return identificacion;
      }
    };

    if (name === 'numeroIdentificacion') {
      if (value.length === 9) {
        setLoaderInput(true);

        const { data, success } = await apiGetPersona({
          identificacion: value,
          tipo: tipoID ? tipoID : '0',
        });

        if (success) {
          setArrayBeneficiarios(prevBeneficiarios => {
            const updatedBeneficiarios = [...prevBeneficiarios];

            updatedBeneficiarios[index] = {
              ...updatedBeneficiarios[index],
              tipoIdentificacion: renderValueIdentidad(
                data.tipoIdentificacion
                  ? data.tipoIdentificacion
                  : updatedBeneficiarios[index].tipoIdentificacion,
              ),
              primerNombre: data.primerNombre || '',
              segundoNombre: data.segundoNombre || '',
              primerApellido: data.primerApellido || '',
              segundoApellido: data.segundoApellido || '',
              fechaNacimiento: data.fechaNacimiento || '',
              [name]: value,
            };
            return updatedBeneficiarios;
          });
        } else {
          setArrayBeneficiarios(prevBeneficiarios => {
            const updatedBeneficiarios = [...prevBeneficiarios];

            updatedBeneficiarios[index] = {
              ...updatedBeneficiarios[index],
              tipoIdentificacion: renderValueIdentidad(
                updatedBeneficiarios[index].tipoIdentificacion
                  ? updatedBeneficiarios[index].tipoIdentificacion
                  : '',
              ),
              primerNombre: '',
              segundoNombre: '',
              primerApellido: '',
              segundoApellido: '',
              fechaNacimiento: '',
              [name]: value,
            };
            return updatedBeneficiarios;
          });
        }

        setLoaderInput(false);
        return;
      } else {
        setArrayBeneficiarios(prevBeneficiarios => {
          const updatedBeneficiarios = [...prevBeneficiarios];

          updatedBeneficiarios[index] = {
            ...updatedBeneficiarios[index],
            [name]: value,
          };
          return updatedBeneficiarios;
        });
      }
    }
    if (name === 'porcentaje' && index === 0 && value === '100') {
      let updatedBeneficiarios = arrayBeneficiarios[0];
      updatedBeneficiarios = {
        ...updatedBeneficiarios,
        [name]: value,
      };

      let beneficiario2 = {
        tipoIdentificacion: '',
        numeroIdentificacion: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: '',
        porcentaje: '',
        mostrar: true,
        titulo: '+ Agregar Otro Beneficiario',
        add: false,
        estado: false,
        tipoIdentificacionError: false,
        numeroIdentificacionError: false,
        primerNombreError: false,
        segundoNombreError: false,
        primerApellidoError: false,
        segundoApellidoError: false,
        parentescoError: false,
        porcentajeError: false,
      };
      setArrayBeneficiarios([updatedBeneficiarios, beneficiario2]);
      return;
    }

    let updatedBeneficiarios = [...arrayBeneficiarios];
    updatedBeneficiarios[index] = {
      ...updatedBeneficiarios[index],
      [name]: value,
    };
    setArrayBeneficiarios(updatedBeneficiarios);

    if (name === 'parentesco') {
      action(updatedBeneficiarios, 'beneficiarios');
    }
  };

  const handleBlur = (recuperar = false) => {
    let beneficiariosA = arrayBeneficiarios;
    const sumPorcentaje = +beneficiariosA[0].porcentaje;

    if (!recuperar) {
      if (sumPorcentaje < 100) {
        const newPorcentaje = 100 - sumPorcentaje;
        let updatedBeneficiarios = [...beneficiarios];

        updatedBeneficiarios[1] = {
          ...updatedBeneficiarios[1],
          porcentaje: newPorcentaje,
        };
        beneficiariosA = updatedBeneficiarios;
      }
      setArrayBeneficiarios(beneficiariosA);
      setBeneficiarios(beneficiariosA);
    }
    setPorcentaje(sumPorcentaje);
    action(beneficiariosA, 'beneficiarios');
  };

  return (
    <div>
      {arrayBeneficiarios.map((beneficiario, index) => (
        <Accordion
          aria-controls="panel1a-content"
          className={`bg-card-fq rounded-[20px] mb-5 ${
            beneficiario.mostrar ? 'block' : 'hidden'
          }`}
          sx={{
            '&:before': {
              display: 'none',
            },
          }}
          expanded={beneficiario.estado}
          key={index}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            onClick={() => {
              if (!beneficiario.add) {
                handleBeneficiario(index);
              } else {
                handleChange(index);
              }
            }}
          >
            <div className="flex justify-between w-full">
              <p className="fontPBold color-primary text-sm md:text-xl">
                {beneficiario.add ? 'Beneficiario' : beneficiario.titulo}
              </p>
              <p className="fontPSemiBold color-secondary text-sm md:text-base">
                {beneficiario.estado ? '- Ver menos' : '+ Ver más'}
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails className="border-t-[1px] border-outline">
            <div className="grid grid-cols-1  my-4  gap-x-4 gap-y-7 md:mx-6 md:grid-cols-2">
              <div>
                <TextCustom
                  text="Tipo de Identificación"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <SelectCustom
                  options={identidades}
                  value={beneficiario.tipoIdentificacion}
                  setValue={handleSetValue}
                  placeholder={'Ingresa el tipo de Identificación'}
                  index={index}
                  nameValue={'tipoIdentificacion'}
                  borderStyle={true}
                  alinear="izquierda"
                  msgError={
                    beneficiario.tipoIdentificacionError
                      ? beneficiario.tipoIdentificacion.length === 0
                        ? 'Tipo de Identificación es requerido'
                        : ''
                      : ''
                  }
                />
              </div>
              <div>
                <TextCustom
                  text="N. de Identificación"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <TextInputCustom
                  type="text"
                  placeholder="Ingresa el número de identificación"
                  onBlur={handleBlur}
                  value={beneficiario.numeroIdentificacion}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'numeroIdentificacion'}
                  typesValidation={typesValidation.multiLettersExtend}
                  msgError={
                    beneficiario.numeroIdentificacionError
                      ? beneficiario.numeroIdentificacion.length === 0
                        ? 'Número de identificación es requerido'
                        : ''
                      : ''
                  }
                  borderStyle={true}
                  maxLength={9}
                  loader={loaderInput}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Primer nombre"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <TextInputCustom
                  type="text"
                  placeholder="Ingresa el primer nombre"
                  value={beneficiario.primerNombre}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'primerNombre'}
                  typesValidation={typesValidation.lettersExtend}
                  msgError={
                    beneficiario.primerNombreError
                      ? beneficiario.primerNombre.length === 0
                        ? 'Primer nombre es requerido'
                        : ''
                      : ''
                  }
                  onBlur={handleBlur}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Segundo nombre"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                />
                <TextInputCustom
                  type="text"
                  placeholder="Ingresa el segundo nombre"
                  value={beneficiario.segundoNombre}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'segundoNombre'}
                  typesValidation={typesValidation.lettersExtend}
                  msgError={
                    beneficiario.segundoNombreError
                      ? beneficiario.segundoNombre.length === 0
                        ? 'Segundo nombre es requerido'
                        : ''
                      : ''
                  }
                  onBlur={handleBlur}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Primer apellido"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <TextInputCustom
                  type="text"
                  placeholder="Ingresa el primer apellido"
                  value={beneficiario.primerApellido}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'primerApellido'}
                  typesValidation={typesValidation.lettersExtend}
                  msgError={
                    beneficiario.primerApellidoError
                      ? beneficiario.primerApellido.length === 0
                        ? 'Primer apellido es requerido'
                        : ''
                      : ''
                  }
                  onBlur={handleBlur}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Segundo apellido"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                />
                <TextInputCustom
                  type="text"
                  placeholder="Ingresa el segundo apellido"
                  value={beneficiario.segundoApellido}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'segundoApellido'}
                  ypesValidation={typesValidation.lettersExtend}
                  msgError={
                    beneficiario.segundoApellidoError
                      ? beneficiario.segundoApellido.length === 0
                        ? 'Segundo apellido es requerido'
                        : ''
                      : ''
                  }
                  onBlur={handleBlur}
                  borderStyle={true}
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Parentesco"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <SelectMultiCustom
                  index={index}
                  value={beneficiario.parentesco}
                  setValue={handleSetValue}
                  options={parentescoArray}
                  placeholder={'Ingresa el parentesco'}
                  isMulti={false}
                  className="mt-1.5"
                  nameValue={'parentesco'}
                  msgError={
                    beneficiario.parentescoError
                      ? beneficiario.parentesco.length === 0
                        ? 'Parentesco es requerido'
                        : ''
                      : ''
                  }
                  alignError="left"
                />
              </div>
              <div>
                <TextCustom
                  text="Participación del beneficiario (%)"
                  component="p"
                  variant="p"
                  className="fontPRegular text-xs md:text-base"
                  required={true}
                />
                <TextInputCustom
                  placeholder="Ingresa la partipación del beneficiario"
                  value={beneficiario.porcentaje}
                  setValue={handleSetValue}
                  index={index}
                  nameValue={'porcentaje'}
                  maxLength={3}
                  typesValidation={typesValidation.onlyNumber}
                  msgError={
                    beneficiario.porcentajeError
                      ? beneficiario.porcentaje.length === 0
                        ? 'Porcentaje partipación del beneficiario es requerido'
                        : ''
                      : ''
                  }
                  onBlur={handleBlur}
                  borderStyle={true}
                  prefix="%"
                  alignError="left"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <ModalAlertas
        open={modal}
        setOpen={setModal}
        severity="warning"
        contenido="No se permiten más beneficiarios, el límite de porcentaje de participación ha sido alcanzado."
      />
    </div>
  );
};

export default CollapseForm;
