import { useEffect, useRef, useState } from 'react';

// Components
import { ButtonCustom, TextCustom, AlertCustom } from '../../atoms';
import { PreguntasForm } from '../../molecules';
import { ModalAlertas, ModalTerminos } from '../../organisms';
import { ModalControlValidacion } from '../../organisms';
import { apiEncontrarPersonaListaControl } from '../../../services/apis/Lista Control/listaControl';
import { renderContactarControlValidacion } from '../../../common/constants/textoModales.js';

// Hooks
import { useApp, useCotizador, useForm } from '../../../hooks/others';

//services/api
import {
  apiActualizarOportunidad,
  apiGetPersona,
} from '../../../services/apis';

//constants/utils
import { renderBoldText } from '../../../common/constants';
import {
  calcularEdad,
  capitalizarPalabra,
  changeQueryParam,
  generarParamsOportunidad,
} from '../../../core/utils';


// Validations
import { formValidDatosPersonales } from '../../../core/validations/formValidDatosPersonales';


const PasoEmision = () => {
  const {
    dataCotizar,
    paso,
    pasos,
    handleSiguientePaso,
    idOportunidad,
    handleRegresarPaso,
    handleValidBeneficiarios,
    setDataCotizar,
    isRecuperandoCotizacion,
    idCotizacion,
    cargando,
    setCargando,
    pasoActual,
    setPasoActual,
    setPasoAnterior,
    pasoAnterior,
    mostrarCampos,
    setComprandoOtroSeguro,
    setMaskedData,
  } = useCotizador();

  const { recaptchaRef } = useApp();

  const [formData, setFormData] = useState('');
  const [formDataError, setFormDataError] = useState('');
  const [modal, setModal] = useState(false);
  const [modalRequisitos, setModalRequisitos] = useState(false);
  const [modalValidacion, setModalValidacion] = useState(false);
  const [beneficiarios, setBeneficiarios] = useState(false);
  const [modalBeneficiarios, setModalBeneficiarios] = useState(false);
  const [modalPorcentaje, setModalPorcentaje] = useState(false);
  const [recuperar, setRecuperar] = useState(false);
  const [mensajeError, setMensajeError] = useState({
    titulo: '',
    mensaje: '',
  });

  const [loaderInput, setLoaderInput] = useState(false);
  const [maskTel, setMaskTel] = useState('####-####');
  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });
  const debounceTimerRef = useRef(null);

  const handleSiguientePasoDebounced = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      handleSiguientePaso();
      setCargando(false);
    }, 300);
  };

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso, pasoActual, setPasoActual, setPasoAnterior]);

  useEffect(() => {
    if (paso === 4) {
      // eslint-disable-next-line no-undef

      const dataF = pasos[paso - 1]?.formData;
      const dataP = dataCotizar?.datosPersonales;

      if (pasoAnterior < 4) delete dataP['beneficiarios'];
      if (dataP?.beneficiarios && dataP.beneficiarios.length < 1)
        delete dataP['beneficiarios'];
      setBeneficiarios(dataF?.beneficiarios !== undefined ? true : false);
      setFormData({ ...dataF, ...dataP });
      setFormDataError(pasos[paso - 1]?.formDataError ?? '');
      setCargando(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso, isRecuperandoCotizacion]);

  useEffect(()=>{
    const dataP = dataCotizar.datosPersonales;

    if(dataP.telefono.includes('*')){
      setMaskTel('****-####')
    }

  },[paso, setMaskTel, dataCotizar.datosPersonales])

  useEffect(() => {
    if (paso === 4 && isRecuperandoCotizacion) {
      changeQueryParam('paso', paso);
    }
  }, [paso, isRecuperandoCotizacion]);

  const aceptacionModalEmisionPoliza = async resp => {
    const respuesta = async () => {
      const respp = await apiEncontrarPersonaListaControl({
        id_persona: formData.numeroIdentificacion,
      });
      return respp;
    };
    const respp = await respuesta();
    if (respp.data.no_cotizar) {
      setModalRequisitos(false);
      setModalValidacion(true);
    } else {
      try {
        const token = await recaptchaRef.current.executeAsync();

        if (token) {
          setModalRequisitos(false);
          setCargando(true);

          const requisitosCodigos = { AEP: '', DDP: '', DDPO: '' };

          if (resp) {
            //* si acepta la emision de la poliza la etapa se pasa a emision

            //codigos de requisitos

            if (dataCotizar.seguro.codigo === 'SEG-ONC') {
              requisitosCodigos.AEP = 'aceptado';
              requisitosCodigos.DDP = 'aceptado';
              requisitosCodigos.DDPO = 'aceptado';
            } else {
              requisitosCodigos.AEP = 'aceptado';
              requisitosCodigos.DDP = 'aceptado';
            }

            const paramsOportunidad = generarParamsOportunidad(
              idOportunidad,
              idCotizacion,
              'Los documentos necesarios han sido aprobados. Se procederá a generar la póliza una vez recibido el pago.',
              'ACEPTACION',
              'EMI',
              'DOC',
              requisitosCodigos,
            );

            const { success: isExito } =
              await apiActualizarOportunidad(paramsOportunidad);

            if (isExito) {
              handleSiguientePasoDebounced();
              setModalRequisitos(false);
            }
          }
          // } else {
          //   requisitosCodigos.AEP = 'rechazado';
          //   requisitosCodigos.DDP = 'aceptado';
          //   requisitosCodigos.DDPO = 'aceptado';

          //   const paramsOportunidad = generarParamsOportunidad(
          //     idOportunidad,
          //     idCotizacion,
          //     'Rechazó Emisión de Póliza',
          //     'ACEPTACION',
          //     'DOC',
          //     'DOC',
          //     requisitosCodigos,
          //   );

          //   await apiActualizarOportunidad(paramsOportunidad);

          //   setRechazado(true);
          //   setCargando(false);
          // }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleValue = async (val = '', valForm) => {
    if (valForm === 'numeroIdentificacion') {
      let newData = { ...formData };
      const tipoID = newData['tipoIdentificacion'];
      //cuando el numero de indentificacion sea igual a 9 se manda llamar el api
      if (val.length === 9) {
        setLoaderInput(true);
        const { data, success } = await apiGetPersona({
          identificacion: val,
          tipo: tipoID ? tipoID : '0',
        });
        let commonData = {};

        if (success) {
          setLoaderInput(false);

          setRecuperar(true);

          commonData = {
            tipoIdentificacion: renderValueIdentidad(
              data.tipoIdentificacion
                ? data.tipoIdentificacion
                : formData.tipoIdentificacion || '0',
            ),
            numeroIdentificacion: val,
            primerNombre: capitalizarPalabra(data.primerNombre) || '',
            segundoNombre: capitalizarPalabra(data.segundoNombre) || '',
            primerApellido: capitalizarPalabra(data.primerApellido) || '',
            segundoApellido: capitalizarPalabra(data.segundoApellido) || '',
            correo: data.correo,
            telefono: data.telefono,
            fechaNacimiento: data?.fechaNacimiento || '',
            edad: calcularEdad(data?.fechaNacimiento) || '',
          };
        } else {
          setRecuperar(false);

          commonData = {
            tipoIdentificacion: renderValueIdentidad(tipoID ? tipoID : '0'),
            numeroIdentificacion: val,
            primerNombre: '',
            segundoNombre: '',
            primerApellido: '',
            segundoApellido: '',
            correo: '',
            telefono: '',
            fechaNacimiento: '',
            edad: '',
          };
          setModal(true);
          setAlert({
            title: '¡Información no encontrada!',
            description:
              'No pudimos completar automáticamente tus datos. Asegúrate de llenar todos los campos obligatorios antes de continuar.',
            severity: 'warning',
          });
          setTimeout(() => {
            setModal(false);
          }, 4000);
        }

        setFormData({
          ...formData,
          ...commonData,
        });

        setLoaderInput(false);
        setComprandoOtroSeguro(false);
      } else {
        let newData = { ...formData };
        newData[valForm] = val;
        setFormData(newData);
      }

      return;
    }
    if (valForm === 'tipoIdentificacion') {
      const commonData = {
        tipoIdentificacion: val,
        numeroIdentificacion: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        correo: '',
        telefono: '',
      };

      if (dataCotizar.seguro.codigo === 'SEG-V') {
        setFormData({
          ...formData,
          ...commonData,
          fechaNacimiento: '',
          edad: '',
        });
      } else {
        setFormData({
          ...formData,
          ...commonData,
        });
      }
      return;
    }
    if (valForm === 'fechaNacimiento') {
      const edadCalculada = calcularEdad(val);

      setFormData({
        ...formData,
        edad: edadCalculada,
        fechaNacimiento: val,
      });

      setDataCotizar(prevState => ({
        ...prevState,
        datosPersonales: {
          ...prevState.datosPersonales,
          ...formData,
          edad: edadCalculada,
          fechaNacimiento: val,
        },
      }));

      return;
    }

    if (valForm === 'primerNombre' || valForm === 'primerApellido') {
      if (val.length !== '') {
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }
    }

    if (valForm === 'correo') {
      if (val.length !== 0) {
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }

      let newData = { ...formData };
      newData[valForm] = val;
      setFormData(newData);
      setMaskedData(prev => ({ ...prev, correo: val }));
      return;
    }

    if (valForm === 'telefono') {
      if (val.length !== 0) {
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }

      if (!val.includes('*')) {
        setMaskTel('####-####');
      }



      if (recuperar) {
        setMaskTel('****-####');
        setRecuperar(false);
      }

      if (val.length === 0) {
        formData.telefono = '';
        setMaskTel('####-####');

        return;
      }

      if (val.length === 9) {
        setFormData(prev => ({ ...prev, telefono: val }));
      }
      return;
    }

    let newData = { ...formData };
    newData[valForm] = val;
    setFormData(newData);
  };

  const handleSubmit = () => {
    setDataCotizar(prevState => ({
      ...prevState,
      datosPersonales: {
        ...prevState.datosPersonales,
        ...formData,
      },
    }));

    if (handleValidForm()) {
      if (beneficiarios) {
        const validBeneficiario = handleValidBeneficiarios();

        if (!validBeneficiario) {
          //lanzando un error si hay en los beneficiarios
          setAlert({
            title: '¡Datos del beneficiario son obligatorios!',
            description:
              'Para continuar, es necesario completar todos los datos del beneficiario.',
            severity: 'error',
          });
          setModal(true);
          return;
        } else if (validBeneficiario.error === 'invalid') {
          if (validBeneficiario.porcentaje < 100) {
            setMensajeError({
              titulo: '¡Completa el porcentaje de participación!',
              mensaje:
                'Agrega otro beneficiario para completar el porcentaje de participación.',
            });
          } else if(validBeneficiario.porcentaje > 100) {
            setMensajeError({
              titulo: '¡Porcentaje de participación fuera de rango!',
              mensaje:
                'Tu porcentaje de participacion esta por arriba del permitido.',
            });
          } else {
            setMensajeError({
              titulo: 'Límite de beneficiarios alcanzado!',
              mensaje:
                'No es posible agregar más beneficiarios, el límite de porcentaje de participación ha sido alcanzado.',
            });
          }
          setModalPorcentaje(true);
          return;
        } else if (validBeneficiario.error === 'sbeneficiarios') {
          setAlert({
            title: '¡Revisa tu porcentaje de participación!',
            description:
              'Porcentaje de participación es incorrecto.',
            severity: 'error',
          });
          setModal(true);
          return;
        } else if (validBeneficiario.error === 'invalidIdentification') {
          setAlert({
            title: '¡Número de identificación incorrecto!',
            description:
              'Las identidades deben ser diferentes.',
            severity: 'error',
          });
          setModal(true);
          return;
        }
      }

      setModalRequisitos(true);
    }
  };
  const handleValidForm = () => {
    let isValid = true;
    let validaciones = [];

    const dataKeys = Object.keys(formData);

    dataKeys.forEach(dataKey => {
      const valor = formData[dataKey];
      pasos.forEach(paso => {
        paso.preguntas.forEach(p => {
          validaciones = validaciones.concat(
            formValidDatosPersonales(p, valor, dataKey),
          );
        });
      });
    });

    const camposConError = validaciones.map(validacion => validacion.campo);

    const formDataErrors = dataKeys.reduce((errors, dataKey) => {
      const campoTieneError = camposConError.includes(dataKey);
      return { ...errors, [`${dataKey}Error`]: campoTieneError };
    }, {});

    //Esto se hizo ya que se tuvo problemas con el campo de fecha de viaje parece que se traiga desde varios formularios atras y lo validaba cuando no es asi.
    validaciones = validaciones.filter(({ campo }) => {
      return campo !== 'fechaViaje';
    });

    if (validaciones.length === 1 && validaciones[0].campo === 'beneficiarios')
      setModalBeneficiarios(true);

    setFormDataError(formDataErrors);
    isValid = validaciones.length === 0;
    if (!isValid) {
      // setModal(true);
      // setTimeout(() => {
      //   setModal(false);
      // }, 3500);
    } else {
      setModal(false);
    }

    return isValid;
  };

  const handleSinBeneficiarios = () => {
    setModalBeneficiarios(false);
    setModalRequisitos(true);
  };

  const renderValueIdentidad = identificacion => {
    switch (identificacion) {
      case 'Nacional':
        return '0';
      default:
        return identificacion;
    }
  };
  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={modal}
        setOpen={setModal}
        severity={alert.severity}
      />

      <div className="mx-auto container mb-10 mt-5 container-datos-personales px-6 md:px-0 xl:px-56">
        <TextCustom
          text="Confirma tus datos"
          variant="h1"
          component="h2"
          className="fontPSemiBold color-primary text-[22px] md:text-[40px] text-center mb-3"
        />
        <TextCustom
          text={`Para la emisión de tu ${
            dataCotizar?.seguro?.nombre.includes('Seguro')
              ? `${dataCotizar?.seguro?.nombre}`
              : `seguro ${dataCotizar?.seguro?.nombre}`
          } confirma que todos tus datos estén correctos.`}
          variant="p"
          component="p"
          className="fontPRegular color-primary text-center text-sm md:text-base"
        />
        <div className="text-center flex flex-wrap grid-form relative">
          {pasos[paso - 1]?.preguntas?.map((pregunta, index) => {
            return (
              <PreguntasForm
                titulo={pregunta?.titulo}
                tipoDato={pregunta?.tipo_dato?.tipo}
                valueForm={pregunta?.value}
                action={handleValue}
                mostrarError={formDataError[pregunta.value + 'Error']}
                msgError={pregunta?.msgError}
                placeholder={pregunta?.placeholder}
                respuestas={pregunta?.respuestas}
                key={index}
                loader={loaderInput}
                mostrarCampos={mostrarCampos}
                className={'form-left'}
                descripcion={pregunta?.descripcion}
                value={formData[pregunta.value]}
                alignError="left"
                formatTel={maskTel}
                classNamePrincipal={
                  pregunta?.value === 'beneficiarios'
                    ? 'w-full'
                    : 'w-full lg:w-[48%] h-auto'
                }
              />
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center">
          <ButtonCustom
            disabled={cargando ? true : false}
            text="Confirmar"
            width="180px"
            onClick={handleSubmit}
          />
          <ButtonCustom
            text="Regresar"
            variant="text"
            className="color-primary fontPSemiBold text-base  mt-3 cursor-pointer underline  lg:text-lg hover:bg-transparent"
            onClick={handleRegresarPaso}
            typeColor="general"
          />
        </div>
        <ModalControlValidacion
          open={modalValidacion}
          setOpen={setModalValidacion}
          titulo="¡Verificación necesaria!"
          contenido="En este momento no podemos proceder debido a una revisión de nuestros registros"
          contactar={renderContactarControlValidacion()}
        />

        <ModalTerminos
          open={modalRequisitos}
          setOpen={setModalRequisitos}
          titulo="Aceptación: Perfeccionamiento del contrato y declaración jurada emisión de Pólizas"
          aceptacionModal={aceptacionModalEmisionPoliza}
          contenido={renderBoldText()}
        />
        <ModalAlertas
          open={modalBeneficiarios}
          setOpen={setModalBeneficiarios}
          severity="info"
          contenido="Es importante declarar uno o más beneficiarios al adquirir tu seguro."
          onClose={true}
          aceptacionModal={handleSinBeneficiarios}
          title="Agrega beneficiarios"
        />
        <ModalAlertas
          open={modalPorcentaje}
          setOpen={setModalPorcentaje}
          severity="warning"
          contenido={mensajeError.mensaje}
          title={mensajeError.titulo}
        />
      </div>
    </>
  );
};

export default PasoEmision;
