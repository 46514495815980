import Select from 'react-select';

// Styles
import './style.css';
import { colors } from '../../styles/theme';

// Components
import MensajeError from '../MensajeError';

const { error, general, bordes, hoverSelect } = colors;

const SelectMultiCustom = ({
  errorAlert = false,
  value = [],
  setValue = () => null,
  options = [],
  isMulti = true,
  placeholder = '',
  msgError = '',
  alignError = '',
  className = '',
  index = '',
  nameValue = '',
  borderStyle = false,
  isDisabled = false
}) => {
  /* const groupedOptions = Object.keys(options).map(continent => ({
    label: continent,
    options: options[continent],
  })); */

  const hadleChange = e => {
    setValue(e, index, nameValue);
    
  };

  return (
    <div className={`App ${className}`}>
      <Select
        className="react-select-container fontPRegular"
        classNamePrefix="react-select"
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        onChange={hadleChange}
        options={options}
        isMulti={isMulti}
        isDisabled = {isDisabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: isDisabled ? "var(--color-disabled)" : "var(--color-contrast-content)", 
            borderColor: state.isFocused ? general : msgError ? error : bordes,
            borderWidth:
              state.isMulti || borderStyle
                ? msgError
                  ? 2
                  : state.isFocused
                  ? 2
                  : 0
                : 1,
            '&:hover': {
              border: state.isFocused && !state.isMulti && '2px solid color-primary',
            },
            borderRadius: 20,
            boxShadow:
              state.isMulti || borderStyle
                ? '0px 6px 40px rgba(0, 55, 99, 0.1)'
                : 'none',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#f0f9fb'
              : state.isFocused
              ? '#e5f4f7' 
              : 'white', 
            color:'black', 
          }),
          singleValue: (base, state) => ({
            ...base,
            color: isDisabled ? "var(--color-disabled-content)" : "var(--color-primary)", 
            fontFamily: 'Poppins-SemiBold',
            fontSize: '14px',
            position: "relative",
            left: "21px",
          }),
          menuList: base => ({
            ...base,
            borderRadius: 20,
            textAlign: 'center',
            marginLeft: 10,
            marginTop:5,
            marginBottom:5
          }),
          placeholder: base => ({
            ...base,
            color: 'var(--color-primary-200)',
            fontFamily: 'Poppins-SemiBold',
          }),
          valueContainer: base =>({
            ...base,
            position: "relative",
          }),
          container: base => ({
            ...base,
          }),
          dropdownIndicator: base =>({
            ...base,
            backgroundColor:isDisabled? "var(--color-disabled)": "var(--color-secondary-100)",
          })
        }}
      />
      {msgError && <MensajeError mensaje={msgError} alignError={alignError} />}
    </div>
  );
};

export default SelectMultiCustom;
