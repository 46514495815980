import React from 'react';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

const ModalControlValidacion = ({
  open = false,
  setOpen = () => null,
  titulo = '',
  contenido = '',
  contactar = '',
}) => {
  // Assets
  const AdvertenciaIcon =
  'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Iconos/modalListaControl.webp';

return (
  <>
    <DialogCustom
      open={open}
      setOpen={setOpen}
      title={'  '}
      disabledDismiss
    >
      <DialogContent>
        <div className="flex gap-14 max-md:gap-5 items-center h-fit p-5 max-md:p-0 max-md:flex-col">
          <div className="flex justify-center items-center w-44">
            <img
              src={AdvertenciaIcon}
              alt="Imagen Control Validación"
              className="w-full h-auto max-w-xs min-w-24 mb-14  max-md:mb-5  max-md:max-w-8  "
            />
          </div>
          <div className="flex flex-col gap-2 w-96 max-md:max-w-60 max-md:items-center ">
            <TextCustom
            text='¡Verificación necesaria!'
            className='text-left max-md:text-center  whitespace-nowrap  text-3xl text-primary  font-[Poppins-Bold] max-md:text-wrap  max-md:text-xl  '
            />

            <TextCustom
              text={contenido}
              className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base font-[Poppins-Regular] max-md:text-sm  "
            />
       
            <TextCustom
              text={contactar}
              className="text-primary-content leading-5 sm:text-sm xsm:text-[15px] md:text-base font-[Poppins-Regular] max-md:text-sm"
            />
            <div className="flex justify-start pt-6 ">
              <ButtonCustom
                text="Chatear con un agente"
                typeColor="general"
                onClick={() => {
                  window.open('https://wa.me/50686473773', '_blank');
                }}
                width="210px"
                height="40px"
                className="mr-5 text-xs sm:text-sm xsm:text-[15px] md:text-base whitespace-nowrap "
              />
            </div>
          </div>
        </div>
      </DialogContent>


    </DialogCustom>
  </>
);
};

export default ModalControlValidacion;
