import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { LoaderCotizar } from '../../atoms';
import { PasoRechazado } from '../../organisms';
import { TabsSeguros } from '../../templates';

// Hooks
import { useCotizador, useApp } from '../../../hooks/others';

// API
import { apiGetSeguros } from '../../../services/apis';

// Functions
import {
  addQueryParam,
  changeQueryParam,
  renderForm,
} from '../../../core/utils';

const FormularioCotizar = () => {
  //context  general de la app
  const { seguros } = useApp();

  //context del cotizador
  const {
    paso,
    setPaso,
    dataCotizar,
    pasos,
    setPasos,
    rechazado,
    setRequisitosModales,
    recuperarCotizacion,
    numPasos,
    cargando,
    setCargando,
  } = useCotizador();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = event => {
      if (paso === 1 || (paso === numPasos && numPasos > 3)) {
        navigate(`/cotizar`);
      } else {
        changeQueryParam('paso', paso - 1);
        setPaso(paso - 1);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        event.preventDefault();
        window.history.pushState(null, null, window.location.href);
      }
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [paso]);

  useEffect(() => {
    const cargarData = async () => {
      setCargando(true);
      //* llamar la funcion que se encarga de obtener los tipos de identidades

      let stateSeguro = location.state;

      if (!stateSeguro) {
        if (seguros.length === 0) stateSeguro = await cargarSeguros();
        else {
          stateSeguro = seguros.find(
            seguro => seguro.codigo === buscarCodigo(),
          );
        }
      }
      let arrayPasos = stateSeguro?.pasos.map(paso => {
        let values = {};
        let valuesError = {};

        // if (paso?.preguntas) {
        //   values = paso.preguntas.reduce((acc, curr) => {
        //     if (curr.value === 'tipoPlan') {
        //       acc[curr.value] = 'Plan Básico';
        //       return acc;
        //     }

        //     acc[curr.value] = '';
        //     return acc;
        //   }, {});

        //   valuesError = paso.preguntas.reduce((acc, curr) => {
        //     if (curr.requerido) {
        //       acc[curr.value + 'Error'] = false;
        //     }
        //     return acc;
        //   }, {});
        // }

        let newPasos = {
          formData: values,
          formDataError: valuesError,
          formulario: renderForm(paso.id.nombre),
          nombre: paso.id.nombre,
          preguntas: paso.preguntas,
        };

        return newPasos;
      });

      let seguro = {
        coberturas: stateSeguro?.coberturas,
        nombre: stateSeguro?.nombre,
        descripcion: stateSeguro?.descripcion,
        imagenes: stateSeguro?.icono,
        codigo: stateSeguro?.codigo,
        monedas: stateSeguro?.monedas,
        factEmp : stateSeguro?.factEmp,
        formasPago: stateSeguro?.formasPago
      };

      dataCotizar.seguro = seguro;
      dataCotizar.productos = stateSeguro?.productos;
      dataCotizar.planes = stateSeguro?.productoSeleccionado.planes;

      if (Cookies.get('_al')) {
        dataCotizar.aliado = Cookies.get('_al');
      }

      //requisitos del context
      const requisitos = {};
      stateSeguro?.requisitos.forEach(item => {
        const { codigo } = item.id;
        requisitos[codigo] = '';
      });

      setRequisitosModales(requisitos);

      const idCotizacionParametro = new URLSearchParams(location.search).get(
        'cotizacion',
      );

      if (idCotizacionParametro) {
        await recuperarCotizacion(arrayPasos);
        setTimeout(() => {
          setCargando(false);
        }, 3000);
      } else {
        addQueryParam('paso', 1);
        setPasos(arrayPasos);
        setTimeout(() => {
          setCargando(false);
        }, 2000);
      }
      //setCargando(false);
    };

    cargarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buscarCodigo = () => {
    let codigoSeguro = '';

    if (location.pathname === '/cotizar-deducible-vehiculo')
      codigoSeguro = 'SEG-DA';
    else if (location.pathname === '/cotizar-enfermedades-graves')
      codigoSeguro = 'SEG-AEG';
    else if (location.pathname === '/cotizar-seguro-viaje')
      codigoSeguro = 'SEG-V';
    else if (location.pathname === '/cotizar-vida-plus')
      codigoSeguro = 'SEG-VID';
    else if (location.pathname === '/cotizar-funerario-plus')
      codigoSeguro = 'SEG-FUNE';
    else if (location.pathname === '/cotizar-seguro-proteccion-multiple')
      codigoSeguro = 'SEG-PM';
    else if (location.pathname === '/cotizar-seguro-oncologico-plus')
      codigoSeguro = 'SEG-ONC';

    return codigoSeguro;
  };

  const cargarSeguros = async () => {
    const response = await apiGetSeguros();
    const { data, success } = response;

    if (success) {
      return data.find(seguro => seguro.codigo === buscarCodigo());
    }
  };
  return (
    <div>
      {cargando && <LoaderCotizar />}
      <TabsSeguros paso={paso} setPaso={setPaso} inputProps={pasos} />
      {rechazado && <PasoRechazado />}
    </div>
  );
};

export default FormularioCotizar;
